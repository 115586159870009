export enum FilterTypes {
  TEXT = 'text',
  OWNER = 'owner',
  APPROVER = 'approver',
  STATUS = 'status',
}

export interface FilterOption {
  type: FilterTypes;
  value: string;
  label: string;
}

export interface ProgressStats {
  completedCount: number;
  totalCount: number;
}
