import { Box, Typography } from '@mui/material';
import {
  MaterializedRequirement,
  RequirementCompletionStatusEnum,
  User,
} from '../../__generated__/gql/graphql';
import UserList from '../../components/UserList';
import { colorThemes } from '../../theme';

const statusColors = {
  finished: '#2E7D32',
  unfinished: '#FF0000',
};

export function RequirementCard({
  onClick,
  specificationNumber,
  evidenceNumber,
  requirement: {
    title,
    description,
    userStatus: status,
    owners,
    approvers,
    approvalResponsibilities,
    ownerResponsibilities,
  },
}: {
  requirement: MaterializedRequirement;
  onClick: () => void;
  specificationNumber: number;
  evidenceNumber: number;
}) {
  const truncatedDescription =
    description!.length > 300
      ? `${description?.substring(0, 300)}...`
      : description;

  const startsWithNumbersOrA = (str: string) => {
    const regex = /^A\.\d+(\.\d+)*\s|^\d+(\.\d+)*\s/;
    return regex.test(str);
  };

  return (
    <Box
      component="button"
      sx={{
        all: 'unset',
        bgcolor: colorThemes.GREY_100,
        color: colorThemes.DARK_BLUE_600,
        borderRadius: '8px',
        padding: '16px',
        display: 'grid',
        gridTemplateColumns: '5fr 1fr 1fr 1.5fr 1.5fr max-content',
        gap: 1,
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Box display="flex" gap="14px" flexDirection="column">
        <Typography
          component="li"
          display="inline"
          key={title}
          fontSize="20px"
          fontWeight={800}
          lineHeight="24px"
          className="list-item"
          starts-with-number={
            startsWithNumbersOrA(title as string) ? 'true' : 'false'
          }
          sx={{
            '&[starts-with-number="true"]::before': {
              content: '""',
            },
            '&[starts-with-number="false"]::before': {
              counterIncrement: 'section',
              content: 'counters(section, ".") " "',
              fontSize: 'inherit',
            },
          }}
        >
          {title}
        </Typography>
        <Typography>{truncatedDescription}</Typography>
      </Box>
      <Box display="flex" gap="14px" flexDirection="column">
        <Typography fontWeight={600}>Evidence</Typography>
        <Box display="flex" alignItems="center" gap="6px">
          <Box
            component="span"
            borderRadius="9999px"
            bgcolor={statusColors.finished}
            width="8px"
            height="8px"
          />
          <span>{evidenceNumber}</span>
        </Box>
      </Box>
      <Box display="flex" gap="14px" flexDirection="column">
        <Typography fontWeight={600}>Specs</Typography>
        <Box display="flex" alignItems="center" gap="6px">
          <Box
            component="span"
            borderRadius="9999px"
            bgcolor={statusColors.finished}
            width="8px"
            height="8px"
          />
          <span>{specificationNumber}</span>
        </Box>
      </Box>
      <Box display="flex" gap="14px" flexDirection="column">
        <Typography fontWeight={600}>Owner</Typography>
        <UserList
          users={owners as User[]}
          variant="text"
          remediationMessage={
            'Please assign one of the following responsibilites to a user: ' +
            ownerResponsibilities?.join(', ')
          }
        />
      </Box>
      <Box display="flex" gap="14px" flexDirection="column">
        <Typography fontWeight={600}>Approval</Typography>
        <UserList
          users={approvers as User[]}
          variant="text"
          remediationMessage={
            'Please assign one of the following responsibilites to a user: ' +
            approvalResponsibilities?.join(', ')
          }
        />
      </Box>
      <Box
        width={20}
        height={20}
        borderRadius="4px"
        bgcolor={
          status?.completionStatus === RequirementCompletionStatusEnum.Approved
            ? statusColors.finished
            : statusColors.unfinished
        }
      />
    </Box>
  );
}
