export const getStepLabels = (
  activeStep: number,
  pdfLoaded: boolean,
  totalPages: number
): string[] => {
  if (activeStep === 0 || !pdfLoaded) return ['Welcome', 'Training Material'];
  return [
    'Welcome',
    ...Array(totalPages)
      .fill(0)
      .map((_, i) => `Page ${i + 1}`),
    'Complete',
  ];
};

export const getCurrentStep = (
  showCompletion: boolean,
  activeStep: number,
  currentPage: number,
  totalPages: number
): number => {
  if (showCompletion) return totalPages + 1;
  if (activeStep === 0) return 0;
  return currentPage;
};
