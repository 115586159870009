import {
  Box,
  List,
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';
import { Link as ReactRouterLink } from 'react-router-dom';
import { ArrowRight } from '@mui/icons-material';
import { MaterializedCategory } from '../../../__generated__/gql/graphql';
import { colorThemes } from '../../../theme';
import { generateTypesafeUrl } from '../../../utils/urlHelpers';

interface SideNavigationProps {
  categories?: MaterializedCategory[];
  wide?: boolean;
}

export const SideNavigation: React.FC<SideNavigationProps> = ({
  categories = [],
  wide = false,
}) => (
  <Box
    gridColumn={wide ? 'span 3' : 'span 2'}
    sx={{
      backgroundColor: colorThemes.DARK_BLUE_600,
      borderRadius: '12px',
      padding: '24px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
      overflow: 'auto',
    }}
  >
    <Typography variant="h4" component="h2" marginBottom="24px" color="white">
      Chapters
    </Typography>

    <List disablePadding>
      {categories.map(category => (
        <ListItem
          key={category?.categoryName}
          disablePadding
          sx={{ marginBottom: '12px' }}
        >
          <ListItemButton
            component={ReactRouterLink}
            to={`#${generateTypesafeUrl(category?.categoryName)}`}
            sx={{
              borderRadius: '8px',
              padding: '4px',
              backgroundColor: colorThemes.DARK_BLUE_600,
              '&:hover': {
                backgroundColor: colorThemes.DARK_BLUE_400,
              },
              display: 'flex',
              alignItems: 'center',
              transition: 'background-color 0.3s',
            }}
          >
            <ListItemIcon sx={{ minWidth: '32px' }}>
              <ArrowRight color="inherit" />
            </ListItemIcon>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="body1" fontWeight="600" color="white">
                {category?.categoryName}
              </Typography>
            </Box>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  </Box>
);
