import {
  RiskClassCategoryEnum,
  RiskClassRoleEnum,
} from '../__generated__/gql/graphql';
import { Answer } from './RiskClassification';

export enum RiskClassSpecialCase {
  GPAI = 'GPAI System',
  SYSTEMIC_RISK_GPAI = 'Systemic Risk GPAI',
  FREE_AND_OPEN_SOURCE_GPAI = 'Free and Open-Source GPAI',
}

const questionType = {
  MULTIPLE_CHOICE: 'multiple-choice',
  SINGLE_CHOICE: 'single-choice',
} as const;

export type QuestionnaireQuestion = {
  id: number;
  title: string;
  description: string;
  options: { name: string; value: number }[];
  type: keyof typeof questionType;
  category: number;
};

export const germanRiskQuestionnaireCategories = [
  'Rolle',
  'Ausnahmen und Verbote',
  'GPAI-System',
  'Hochrisiko-KI-System',
  'System mit begrenztem Risiko',
];

export const englishRiskQuestionnaireCategories = [
  'Role',
  'Exemptions and Prohibitions',
  'GPAI-Modell',
  'High Risk AI System',
  'Limited Risk AI System',
];

export const possibleSpecialCaseOnQuestion: {
  [key: number]: RiskClassSpecialCase[];
} = {
  13: [RiskClassSpecialCase.GPAI],
  14: [RiskClassSpecialCase.SYSTEMIC_RISK_GPAI, RiskClassSpecialCase.GPAI],
  15: [
    RiskClassSpecialCase.FREE_AND_OPEN_SOURCE_GPAI,
    RiskClassSpecialCase.GPAI,
  ],
};

export const englishRiskClassificationQuestionnaireQuestions: QuestionnaireQuestion[] =
  [
    {
      id: 0,
      title: 'Are you developing or procuring an AI system?',
      description:
        'Developing or procuring the AI system has an impact on your potential obligations under the EU AI Act.\nHint: Fine-tuning is likely considered developing, while retrieval augmented generation likely is not considered developing under the Act. If you are merely integrating and using a third-party AI system without any modification, you are not considered as a developer.',
      options: [
        {
          name: "I'm the developer of the AI system (or someone developed it in my name)",
          value: 0,
        },
        {
          name: "I'm the developer of the AI system (or someone developed it in my name), and I'm using it myself",
          value: 1,
        },
        { name: "I'm procuring and using the AI system myself", value: 2 },
        { name: 'None of these', value: 3 },
      ],
      type: 'SINGLE_CHOICE',
      category: 0,
    },
    {
      id: 1,
      title: 'Do you make the AI system available within the EU?',
      description:
        'Meaning: Are you the first one supplying the AI system for distribution or use on the EU market in the course of a commercial activity (whether for payment or free of charge)?',
      options: [
        { name: "Yes, I'm making it available in the EU", value: 0 },
        { name: "No, I'm not the first", value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 0,
    },
    {
      id: 2,
      title: 'Are you putting the AI system into service in the EU?',
      description:
        'Meaning: Are you the first one supplying the AI system directly to a deployer ("user"), including yourself?\nHint: Both for internal or external use of the AI system.',
      options: [
        { name: "Yes, I'm putting it into service", value: 0 },
        { name: "No, I'm not the first", value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 0,
    },
    {
      id: 3,
      title: 'Are you doing so under your own name or trademark?',
      description:
        'Meaning: The AI system is associated with your organization.',
      options: [
        { name: "Yes, it's under my name or brand", value: 0 },
        { name: "No, it's the name or brand of someone else", value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 0,
    },
    {
      id: 4,
      title:
        "Did you substantially modify the AI system or change it's intended purpose?",
      description:
        "Changing an AI system (in it's intended purpose) after it has been already placed on the market or been put into operation can impact your obligations under the EU AI Act.",
      options: [
        {
          name: "Yes, I changed the system a lot or changed it's purpose",
          value: 0,
        },
        { name: 'No, I did not', value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 0,
    },
    {
      id: 5,
      title:
        'Will you integrate the AI system as a safety component into the following products and put your name or trademark on it?',
      description:
        "The relevant products include (see Annex I, section A of the AI Act):\nMachinery, toy safety, recreational craft or personal watercraft, lifts and it's safety components, equipment and protective systems in potentially explosive atmospheres, radio equipment, pressure equipment, cableway installations, personal protective equipment, appliances to burn gaseous fuels, medical devices and in-vitro diagnostic medical devices.",
      options: [
        {
          name: 'Yes, it will be integrated as a product safety component in one of these categories',
          value: 0,
        },
        { name: 'No, it will not', value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 0,
    },
    // next 2 qustions maybe needed in the future again
    {
      id: 6,
      title: 'Are you located or established in the EU?',
      description: '',
      options: [
        { name: 'Yes, I am', value: 0 },
        { name: 'No, I am not located or established in the EU', value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 0,
    },
    {
      id: 7,
      title:
        'Will the output of the AI system be used (by recipients) in the EU?',
      description:
        'This can also be true even though you are not based in the EU.',
      options: [
        { name: 'Yes, it will likely be used within the EU', value: 0 },
        { name: "No, it's not", value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 0,
    },
    {
      id: 8,
      title:
        'Are you carrying out the AI Act obligations on behalf of an AI system provider?',
      description:
        'Meaning: You are authorized by written mandate to represent a provider who is based outside the EU.',
      options: [
        { name: "Yes, I'm representing someone else", value: 0 },
        { name: "No, I'm not", value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 0,
    },

    {
      id: 9,
      title: 'Does your AI system pursue one of the following purposes?',
      description: 'There are some exemptions (see Article 2 in the AI Act).',
      options: [
        {
          name: "It's only used for scientific research and development",
          value: 0,
        },
        {
          name: "It's exclusively used for military, defense or national security purposes",
          value: 1,
        },
        {
          name: "It's used for law enforcement or judicial cooperation by a non-EU public authority or international organization together with the EU (Member States)",
          value: 2,
        },
        {
          name: 'None of these',
          value: 3,
        },
      ],
      type: 'MULTIPLE_CHOICE',
      category: 1,
    },
    {
      id: 10,
      title: 'Is your AI system released under a free and open-source license?',
      description: 'There are some exemptions (see Article 2 in the AI Act).',
      options: [
        {
          name: "Yes, it's for free and open- source",
          value: 0,
        },
        { name: "No, it's not", value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 1,
    },
    {
      id: 11,
      title: 'Is your AI System used for one of the following purposes?',
      description:
        'There are some prohibited use-cases (see Article 5 in the AI Act).',
      options: [
        {
          name: "Manipulate or deceive a person (subconsciously), such that this person's behavior or decision-making is distorted, leading to significant harm to someone",
          value: 0,
        },
        {
          name: "Exploit the vulnerabilities of a person due to their age, disability, or social or economic situation, such that this person's behavior or decision-making is distorted, leading to significant harm to someone",
          value: 1,
        },
        {
          name: 'Score or classify people based on social behavior or personality traits ("Social Scoring"), leading to unjustified or unfavorable treatment of them',
          value: 2,
        },
        {
          name: 'Assess or predict the risk of a person committing a crime based on personality traits',
          value: 3,
        },
        {
          name: 'Create or expand facial recognition databases through untargeted scraping of facial images',
          value: 4,
        },
        {
          name: 'Inferring emotions of people at the workplace or educational institutions, except for medical or safety purposes',
          value: 5,
        },
        {
          name: 'Biometric categorization systems deducing or inferring race, political opinions, trade union membership, religious beliefs, sex life, or sexual orientation',
          value: 6,
        },
        {
          name: 'Real-time remote biometric identification in public spaces for law enforcement',
          value: 7,
        },
        {
          name: 'None of the above',
          value: 8,
        },
      ],
      type: 'MULTIPLE_CHOICE',
      category: 1,
    },
    {
      id: 12,
      title: 'Are you providing a GPAI model?',
      description:
        'A general-purpose AI or foundation model, which is trained on a large amount of data and can perform various distinct tasks (see Article 3 in the AI Act).',
      options: [
        {
          name: 'Yes, I provide a GPAI / foundation model',
          value: 0,
        },
        { name: 'No', value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 2,
    },
    {
      id: 13,
      title: 'Does your GPAI model have high capabilities?',
      description:
        'GPAI models that have high capabilities pose systemic risks (see Article 51 in the AI Act).\nThe model has high capabilities if one of these conditions is met:\n\n- The cumulative amount of computing power(used for training) is above 10^ 25 FLOPS\n- The European Commission classifies the GPAI model as a model with high capabilities(e.g.due to the number of parameters, the input or output modalities, or to the reach in the EU market, for instance when the GPAI model is accessible to at least 10,000 business users)',
      options: [
        {
          name: 'Yes, it has high capabilities',
          value: 0,
        },
        { name: 'No, it does not classify as such', value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 2,
    },
    {
      id: 14,
      title:
        'Can you argue that your GPAI model does not have a systemic risk?',
      description:
        'GPAI models that have high capabilities pose systemic risks (see Article 51 in the AI Act). If you have sufficiently substantiated arguments that demonstrate to the European Commission that your GPAI model cannot pose a systemic risk, you can avoid additional obligations (see Article 52 in the AI Act).',
      options: [
        {
          name: 'Yes, it definitely has no systemic risk',
          value: 0,
        },
        { name: 'It likely poses a systemic risk', value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 2,
    },
    {
      id: 15,
      title: 'Is the GPAI free and open-source?',
      description:
        'GPAI models that are released under a free and open-source license that allows for the access, usage, modification, and distribution of the model, and whose parameters, including the weights, the information on the model architecture, and the information on model usage, are made publicly available, can avoid additional obligations (see Article 53 in the AI Act).',
      options: [
        {
          name: 'Yes, the GPAI model is free, open-source and meets the conditions above',
          value: 0,
        },
        {
          name: 'No, it does not meet the conditions above',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 2,
    },
    {
      id: 16,
      title: 'Is the AI System used as a safety component in a product?',
      description: '',
      options: [
        {
          name: 'Yes',
          value: 0,
        },
        {
          name: "No, it's not",
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 17,
      title:
        'Is your AI system used in one of the following products or sectors?',
      description:
        'There are some use-cases that are always classified as high-risk if they act as a safety component of already regulated products or if the system itself is an already regulated product (see Article 6 or Annex I in the AI Act)',
      options: [
        {
          name: 'Civil aviation (security)',
          value: 0,
        },
        {
          name: 'Two-, three- and four-wheeled vehicles',
          value: 1,
        },
        {
          name: 'Agricultural and forestry vehicles',
          value: 2,
        },
        {
          name: 'Motor vehicles and motor vehicle trailers, as well as systems, components or separate technical units of these vehicles',
          value: 3,
        },
        {
          name: 'Marine equipment',
          value: 4,
        },
        {
          name: 'Interoperability of the railway system',
          value: 5,
        },
        {
          name: 'None of the above',
          value: 6,
        },
      ],
      type: 'MULTIPLE_CHOICE',
      category: 3,
    },
    {
      id: 18,
      title:
        'Is your AI system used in one of the following products or sectors?',
      description:
        'There are some use-cases that are always classified as high-risk if they act as a safety component of already regulated products or if the system itself is an already regulated product (see Article 6 or Annex I in the AI Act)',
      options: [
        {
          name: 'Machinery',
          value: 0,
        },
        {
          name: 'Toys',
          value: 1,
        },
        {
          name: 'Recreational craft and personal watercraft',
          value: 2,
        },
        {
          name: 'Lifts and safety components of lifts',
          value: 3,
        },
        {
          name: 'Equipment and protective systems in potentially explosive atmospheres',
          value: 4,
        },
        {
          name: 'Radio equipment',
          value: 5,
        },
        {
          name: 'Pressure equipment',
          value: 6,
        },
        {
          name: 'Ropeways / cableway installations',
          value: 7,
        },
        {
          name: 'Personal protective equipment',
          value: 8,
        },
        {
          name: 'Appliance for burning gaseous fuels',
          value: 9,
        },
        {
          name: 'Medical devices',
          value: 10,
        },
        {
          name: 'In vitro diagnostic medical devices',
          value: 11,
        },
        {
          name: 'None of the above',
          value: 12,
        },
      ],
      type: 'MULTIPLE_CHOICE',
      category: 3,
    },
    {
      id: 19,
      title: 'Do you need to undergo a third-party conformity assessment?',
      description:
        "When you are required to undergo a third-party conformity assessment for your regulated product from the prior question, you'll likely need to comply with the EU AI Act.Please check the applicable laws of your product category(see Annex I, Section A in the AI Act).",
      options: [
        {
          name: 'Yes, a conformity assessment is required',
          value: 0,
        },
        {
          name: "No, it's not",
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 20,
      title: 'Is your AI system used in one of the following areas?',
      description:
        'There are some use-cases that are classified as high-risk because they can significantly harm people or their fundamental rights if the AI system fails or is misused. (see Article 6 or Annex III in the AI Act)',
      options: [
        {
          name: 'Biometrics, incl. emotion recognition',
          value: 0,
        },
        {
          name: 'Critical infrastructure, incl. digital infrastructure, road traffic and energy supply',
          value: 1,
        },
        {
          name: 'Education, incl. evaluating learning outcomes and access to education',
          value: 2,
        },
        {
          name: 'Human Resources, incl. recruitment, task allocation and promotion or termination decisions',
          value: 3,
        },
        {
          name: 'Essential private and public benefits or services, incl. finance, insurance, healthcare and emergency services',
          value: 4,
        },
        {
          name: 'Law enforcement',
          value: 5,
        },
        {
          name: 'Migration, asylum and border controls, incl. security risk assessments and examination of residence permits',
          value: 6,
        },
        {
          name: 'Administration of justice and democratic processes, incl. interpreting facts and the law and elections',
          value: 7,
        },
        {
          name: 'None of the above',
          value: 8,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 21,
      title: 'Is the AI system used for the following purposes?',
      description:
        'AI systems used for:\n\n- Remote biometric identification (not including systems used for biometric verification of persons)\n- Biometric categorization using sensitive characteristics of persons\n- Emotion recognition',
      options: [
        { name: 'Yes', value: 0 },
        {
          name: 'No',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 22,
      title: 'Is the AI system used for the following purposes?',
      description:
        'AI systems used as safety component for the management and operation of:\n\n- critical digital infrastructure\n- road traffic\n- Supply of water, gas, heating or electricity',
      options: [
        { name: 'Yes', value: 0 },
        {
          name: 'No',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 23,
      title: 'Is the AI system used for the following purposes?',
      description:
        'AI systems used for:\n\n- determining access & admission to educational institutions\n- evaluating learning outcomes\n- assessing the appropriate level of education someone will receive\n- monitoring & detecting prohibited behavior of students during a test',
      options: [
        { name: 'Yes', value: 0 },
        {
          name: 'No',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 24,
      title: 'Please specify the area in which the AI system is used',
      description: '',
      options: [
        { name: 'Recruitment', value: 0 },
        {
          name: 'Employment / Work-Related Relationship',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 25,
      title: 'Please specify the area in which the AI system is used',
      description: '',
      options: [
        { name: 'Banking / Finance', value: 0 },
        {
          name: 'Insurance',
          value: 1,
        },
        {
          name: 'Public Services',
          value: 2,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 26,
      title: 'Is the AI system used for the following purposes?',
      description:
        'AI systems used:\n\n- for assessing the risk of a person becoming a victim of a criminal offense\n- as a polygraph or similar\n- for evaluating the reliability of evidence when investigating criminal offenses\n- for assessing the risk of a person offending\n- for assessing personality traits based on past criminal behavior\n- for profiling persons when investigating criminal offenses',
      options: [
        { name: 'Yes', value: 0 },
        {
          name: 'No',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 27,
      title: 'Is the AI system used for the following purposes?',
      description:
        'AI systems used:\n\n- as polygraphs or similar\n- for assessing the security risk of a person entering an EU state\n- for assessing the risk of irregular migration of a person entering an EU state\n- for assessing the health risk of a person entering an EU state\n- for assessing applications for asylum, visa or residence permits and associated complaints\n- for detecting, recognising or identifying persons (not including the verification of travel documents)',
      options: [
        { name: 'Yes', value: 0 },
        {
          name: 'No',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 28,
      title: 'Is the AI system used for the following purposes?',
      description:
        'AI systems used for:\n\n- researching and interpreting the law to a specific set of facts or for a similar way in alternative dispute resolutions\n- influencing the outcome of an election or referendum or the voting behavior of persons (not including AI systems that are used for logistical or administrative purposes)',
      options: [
        { name: 'Yes', value: 0 },
        {
          name: 'No',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 29,
      title: 'Is the AI system used for the following purposes?',
      description:
        'AI systems used during the recruitment and selection process, incl.:\n\n- placing targeted job advertisments\n- analyzing and filtering job applications\n- evaluating candidates',
      options: [
        { name: 'Yes', value: 0 },
        {
          name: 'No',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 30,
      title: 'Is the AI system used for the following purposes?',
      description:
        'AI systems used to:\n\n- make decisions affecting terms of work-related relationships\n- make decisions affecting promotions\n- make decisions affecting terminations\n- allocate tasks based on individual behavior or characteristics of persons\n- monitor and evaluate the work-related performance and behavior of persons',
      options: [
        { name: 'Yes', value: 0 },
        {
          name: 'No',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 31,
      title: 'Is the AI system used for the following purposes?',
      description:
        'AI systems used to evaluate the creditworthiness of a person or to create a credit score',
      options: [
        { name: 'Yes', value: 0 },
        {
          name: 'No',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 32,
      title: 'Is the AI system used for the following purposes?',
      description:
        'AI systems used for risk assessments and pricing regarding:\n\n- health insurances\n- life insurances',
      options: [
        { name: 'Yes', value: 0 },
        {
          name: 'No',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 33,
      title: 'Is the AI system used for the following purposes?',
      description:
        'AI systems used to:\n\n- evaluate the eligibility of persons for essential public benefits and services\n- grant, reduce, revoke or reclaim such benefits and services\n- dispatch, evaluate and prioritize ermegency services ',
      options: [
        { name: 'Yes', value: 0 },
        {
          name: 'No',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },

    {
      id: 34,
      title: 'Can you argue that the high-risk AI system is not harmful?',
      description:
        'A high-risk AI system is not considered posing significant harm to the health, safety or fundamental rights of people if at least one of these conditions is met:\n\n- The AI system performs a narrow procedural task\n- The AI system only improves the result of a previous human activity\n- The AI system only detects deviations from previous decision patterns and does not replace or influence the human assessment\n- The AI system is intended to perform a preparatory task to an assessment relevant for the purposes of the use cases listed in Annex III',
      options: [
        {
          name: "It's not harmful, even though it falls under the high-risk category (i.e. one of the conditions above is met)",
          value: 0,
        },
        {
          name: 'It could be harmful if the system fails or is misused',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 35,
      title: 'Is your AI System used for one of the following purposes?',
      description:
        'There are some use-cases that are subject to transparency obligations (see Article 50 in the AI Act).',
      options: [
        {
          name: 'Interacts with people',
          value: 0,
        },
        {
          name: 'Generates synthetic content (e.g., text, video, audio, images)',
          value: 1,
        },
        {
          name: 'None of these',
          value: 2,
        },
      ],
      type: 'MULTIPLE_CHOICE',
      category: 4,
    },
    {
      id: 36,
      title: 'Is your AI System used for one of the following purposes?',
      description:
        'There are some use-cases that are subject to transparency obligations (see Article 50 in the AI Act).',
      options: [
        {
          name: 'Recognizes the emotions of a person or categorizes biometrics',
          value: 0,
        },
        {
          name: 'Generates or manipulates content (video, audio, images), which can be used for Deep Fakes',
          value: 1,
        },
        {
          name: 'Generates or manipulates text, used to inform the public',
          value: 2,
        },
        {
          name: 'None of these',
          value: 3,
        },
      ],
      type: 'MULTIPLE_CHOICE',
      category: 4,
    },
    {
      id: 37,
      title: 'Your Result:',
      description: '',
      options: [],
      type: 'SINGLE_CHOICE',
      category: 5,
    },
  ];

export const germanRiskClassificationQuestionnaireQuestions: QuestionnaireQuestion[] =
  [
    {
      id: 0,
      title: 'Entwickeln oder nutzen Sie ein KI-System?',
      description:
        'Die Entwicklung oder Nutzung eines KI-Systems hat Auswirkungen auf Ihre potenziellen Verpflichtungen gemäß der KI Verordnung.\nHinweis: Fine-tuning wird wahrscheinlich als Entwicklung betrachtet, während “Retrieval Augmented Generation” (RAG) wahrscheinlich nicht als Entwicklung unter der KI-VO angesehen wird. Wenn Sie lediglich ein KI-System eines Drittanbieters ohne jegliche Modifikation integrieren und verwenden, gelten Sie nicht als Entwickler.',
      options: [
        {
          name: 'Ich bin der Entwickler des KI-Systems (oder jemand hat es in meinem Namen entwickelt)',
          value: 0,
        },
        {
          name: 'Ich bin der Entwickler des KI-Systems (oder jemand hat es in meinem Namen entwickelt), und ich benutze es selbst',
          value: 1,
        },
        { name: 'Ich beschaffe und verwende das KI-System selbst', value: 2 },
        { name: 'Keine dieser Optionen', value: 3 },
      ],
      type: 'SINGLE_CHOICE',
      category: 0,
    },
    {
      id: 1,
      title: 'Stellen Sie das KI-System innerhalb der EU zur Verfügung?',
      description:
        'Bedeutung: Sind Sie der Erste, der das KI-System im Rahmen einer kommerziellen Tätigkeit (entgeltlich oder unentgeltlich) auf dem EU-Markt zur Verteilung oder Nutzung bereitstellt?',
      options: [
        { name: 'Ja, ich stelle es in der EU zur Verfügung', value: 0 },
        { name: 'Nein, ich bin nicht der Erste', value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 0,
    },
    {
      id: 2,
      title: 'Setzen Sie das KI-System in der EU in Betrieb?',
      description:
        'Bedeutung: Sind Sie der Erste, der das KI-System direkt einem Anwender ("Benutzer"), einschließlich Ihnen selbst, zur Verfügung stellt?\nHinweis: Sowohl für den internen als auch externen Gebrauch des KI-Systems.',
      options: [
        { name: 'Ja, ich setze es in Betrieb', value: 0 },
        { name: 'Nein, ich bin nicht der Erste', value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 0,
    },
    {
      id: 3,
      title: 'Tun Sie dies unter Ihrem eigenen Namen oder Markenzeichen?',
      description:
        'Bedeutung: Das KI-System ist mit Ihrer Organisation verbunden.',
      options: [
        { name: 'Ja, es ist unter meinem Namen oder meiner Marke', value: 0 },
        {
          name: 'Nein, es ist der Name oder die Marke einer anderen Person',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 0,
    },
    {
      id: 4,
      title:
        'Haben Sie das KI-System erheblich modifiziert oder seinen Zweck geändert?',
      description:
        'Die Änderung eines KI-Systems (in seinem beabsichtigten Zweck), nachdem es bereits auf den Markt gebracht oder in Betrieb genommen wurde, kann Auswirkungen auf Ihre Verpflichtungen gemäß der KI Verordnung haben.',
      options: [
        {
          name: 'Ja, ich habe das System stark verändert oder seinen Zweck geändert',
          value: 0,
        },
        { name: 'Nein, habe ich nicht', value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 0,
    },
    {
      id: 5,
      title:
        'Werden Sie das KI-System als Sicherheitskomponente in eines der folgenden Produkte integrieren und Ihren Namen oder Ihr Markenzeichen darauf anbringen?',
      description:
        'Die relevanten Produkte umfassen (siehe Anhang I, Abschnitt A der KI Verordnung):\nMaschinen, Spielzeugsicherheit, Sportboote oder persönliche Wasserfahrzeuge, Aufzüge und deren Sicherheitskomponenten, Geräte und Schutzsysteme in explosionsgefährdeten Bereichen, Funkanlagen, Druckgeräte, Seilbahnanlagen, persönliche Schutzausrüstungen, Gasgeräte, Medizinprodukte und In-vitro-Diagnostika.',
      options: [
        {
          name: 'Ja, es wird als Sicherheitskomponente in eine dieser Kategorien integriert',
          value: 0,
        },
        { name: 'Nein, wird es nicht', value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 0,
    },
    // Weitere Fragen werden möglicherweise in Zukunft wieder benötigt
    {
      id: 6,
      title: 'Sind Sie in der EU ansässig oder niedergelassen?',
      description: '',
      options: [
        { name: 'Ja, bin ich', value: 0 },
        {
          name: 'Nein, ich bin nicht in der EU ansässig oder niedergelassen',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 0,
    },
    {
      id: 7,
      title:
        'Wird die Ausgabe des KI-Systems (von Empfängern) in der EU verwendet?',
      description:
        'Dies kann auch zutreffen, wenn Sie nicht in der EU ansässig sind.',
      options: [
        { name: 'Ja, es wird wahrscheinlich in der EU verwendet', value: 0 },
        { name: 'Nein, wird es nicht', value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 0,
    },
    {
      id: 8,
      title:
        'Erfüllen Sie die Verpflichtungen der KI Verordnung im Namen eines Anbieters von KI-Systemen?',
      description:
        'Bedeutung: Sie sind durch schriftliches Mandat autorisiert, einen Anbieter zu vertreten, der außerhalb der EU ansässig ist.',
      options: [
        { name: 'Ja, ich vertrete jemanden', value: 0 },
        { name: 'Nein, tue ich nicht', value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 0,
    },

    {
      id: 9,
      title: 'Verfolgt Ihr KI-System einen der folgenden Zwecke?',
      description:
        'Es gibt einige Ausnahmen (siehe Artikel 2 der KI Verordnung).',
      options: [
        {
          name: 'Es wird nur für wissenschaftliche Forschung und Entwicklung verwendet',
          value: 0,
        },
        {
          name: 'Es wird ausschließlich für militärische, Verteidigungs- oder nationale Sicherheitszwecke verwendet',
          value: 1,
        },
        {
          name: 'Es wird von einer nicht-EU-Behörde oder internationalen Organisation zusammen mit der EU (Mitgliedstaaten) für Strafverfolgung oder justizielle Zusammenarbeit verwendet',
          value: 2,
        },
        {
          name: 'Keine dieser Optionen',
          value: 3,
        },
      ],
      type: 'MULTIPLE_CHOICE',
      category: 1,
    },
    {
      id: 10,
      title:
        'Wird Ihr KI-System unter einer freien und Open-Source-Lizenz veröffentlicht?',
      description:
        'Es gibt einige Ausnahmen (siehe Artikel 2 der KI Verordnung).',
      options: [
        {
          name: 'Ja, es ist kostenlos und Open-Source',
          value: 0,
        },
        { name: 'Nein', value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 1,
    },
    {
      id: 11,
      title:
        'Kann Ihr KI-System für einen der folgenden Zwecke verwendet werden?',
      description:
        'Es gibt einige verbotene Anwendungsfälle (siehe Artikel 5 der KI Verordnung).',
      options: [
        {
          name: 'Manipulieren oder täuschen einer Person (auch unbewusst), sodass das Verhalten oder die Entscheidungsfindung dieser Person verzerrt wird, was zu erheblichem Schaden für jemanden führt',
          value: 0,
        },
        {
          name: 'Ausnutzen der Schwächen einer Person aufgrund ihres Alters, ihrer Behinderung oder ihrer sozialen oder wirtschaftlichen Lage, sodass das Verhalten oder die Entscheidungsfindung dieser Person verzerrt wird, was zu erheblichem Schaden für jemanden führt',
          value: 1,
        },
        {
          name: 'Bewertung oder Klassifizierung von Menschen basierend auf ihrem sozialen Verhalten oder Persönlichkeitsmerkmalen ("Social Scoring"), was zu ungerechtfertigter oder schlechterer Behandlung führt',
          value: 2,
        },
        {
          name: 'Bewertung oder Vorhersage des Risikos, dass eine Person ein Verbrechen begeht, basierend auf Persönlichkeitsmerkmalen',
          value: 3,
        },
        {
          name: 'Erstellen oder Erweitern von Gesichtserkennungsdatenbanken durch ungezieltes Sammeln von Gesichtsaufnahmen',
          value: 4,
        },
        {
          name: 'Erfassung von Emotionen von Personen am Arbeitsplatz oder in Bildungseinrichtungen, ausgenommen von medizinischen oder sicherheitsrelevanten Zwecken',
          value: 5,
        },
        {
          name: 'Biometrische Kategorisierungssysteme, die Herkunft, politische Meinungen, Gewerkschaftszugehörigkeit, religiöse Überzeugungen, das Sexualleben oder die sexuelle Orientierung ableiten oder erfassen',
          value: 6,
        },
        {
          name: 'Echtzeit-Fernbiometrische Identifikation in öffentlichen Räumen für die Strafverfolgung',
          value: 7,
        },
        {
          name: 'Keine der oben genannten',
          value: 8,
        },
      ],
      type: 'MULTIPLE_CHOICE',
      category: 1,
    },
    {
      id: 12,
      title: 'Sind Sie Anbieter eines GPAI-Modells',
      description:
        'Ein KI-Modell mit allgemeinem Verwendungszweck (”GPAI”) basiert auf einem Grundlagenmodell (”Foundation Model”), das auf einer großen Menge an Daten trainiert wurde und verschiedene unterschiedliche Aufgaben ausführen kann (siehe Artikel 3 der KI Verordnung).',
      options: [
        {
          name: 'Ja, Ich bin Anbieter eines GPAI / Foundation Model',
          value: 0,
        },
        { name: 'Nein', value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 2,
    },
    {
      id: 13,
      title: 'Hat Ihr GPAI-Modell Fähigkeiten mit hohem Wirkungsgrad?',
      description:
        'GPAI-Modelle die Fähigkeiten mit hohem Wirkungsgrad besitzen stellen systemische Risiken dar (siehe Artikel 51 der KI Verordnung).\nDas Modell hat hohe Fähigkeiten, wenn eine der folgenden Bedingungen erfüllt ist:\n\n- Die kumulative Rechenleistung (für das Training verwendet) übersteigt 10^25 FLOPS\n- Die Europäische Kommission klassifiziert das GPAI-Modell als Modell mit hohen Fähigkeiten (z.B. aufgrund der Anzahl der Parameter, der Ein- oder Ausgabemodalitäten oder der Reichweite auf dem EU-Markt, zum Beispiel, wenn das GPAI-Modell für mindestens 10.000 Geschäftskunden zugänglich ist)',
      options: [
        {
          name: 'Ja, es hat hohe Fähigkeiten',
          value: 0,
        },
        { name: 'Nein, es wird nicht als solches eingestuft', value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 2,
    },
    {
      id: 14,
      title:
        'Können Sie argumentieren, dass Ihr GPAI-Modell kein systemisches Risiko darstellt?',
      description:
        'GPAI-Modelle die Fähigkeiten mit hohem Wirkungsgrad besitzen stellen systemische Risiken dar (siehe Artikel 51 der KI Verordnung). Wenn Sie ausreichend fundierte Argumente vorlegen können, die der Europäischen Kommission nachweisen, dass Ihr GPAI-Modell kein systemisches Risiko darstellen kann, können Sie zusätzliche Verpflichtungen vermeiden (siehe Artikel 52 der KI Verordnung).',
      options: [
        {
          name: 'Ja, es besteht definitiv kein systemisches Risiko',
          value: 0,
        },
        { name: 'Es besteht wahrscheinlich ein systemisches Risiko', value: 1 },
      ],
      type: 'SINGLE_CHOICE',
      category: 2,
    },
    {
      id: 15,
      title: 'Ist das GPAI-Modell kostenlos und Open-Source?',
      description:
        'GPAI-Modelle, die unter einer kostenlosen und Open-Source-Lizenz veröffentlicht werden, die den Zugang, die Nutzung, die Modifikation und die Verbreitung des Modells erlaubt und deren Parameter, einschließlich der Gewichte, Informationen zur Modellarchitektur und Informationen zur Modellanwendung öffentlich zugänglich gemacht werden, können zusätzliche Verpflichtungen vermeiden (siehe Artikel 53 der KI Verordnung).',
      options: [
        {
          name: 'Ja, das GPAI-Modell ist kostenlos, Open-Source und erfüllt die oben genannten Bedingungen',
          value: 0,
        },
        {
          name: 'Nein, es erfüllt die oben genannten Bedingungen nicht',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 2,
    },
    {
      id: 16,
      title:
        'Wird das KI System als Sicherheitskomponente in einem Produkt verwendet?',
      description: '',
      options: [
        {
          name: 'Ja',
          value: 0,
        },
        {
          name: 'Nein, ist es nicht',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 17,
      title:
        'Wird Ihr KI-System in einem der folgenden Produkte oder Sektoren verwendet?',
      description:
        'Es gibt einige Anwendungsfälle, die immer als hochriskant eingestuft werden, wenn sie als Sicherheitskomponente bereits regulierter Produkte fungieren oder wenn das System selbst ein bereits reguliertes Produkt ist (siehe Artikel 6 oder Anhang I der KI Verordnung)',
      options: [
        {
          name: 'Zivile Luftfahrt (Sicherheit)',
          value: 0,
        },
        {
          name: 'Zwei-, drei- und vierrädrige Fahrzeuge',
          value: 1,
        },
        {
          name: 'Land- und Forstwirtschaftliche Fahrzeuge',
          value: 2,
        },
        {
          name: 'Kraftfahrzeuge und Anhänger von Kraftfahrzeugen sowie Systeme, Komponenten oder getrennte technische Einheiten dieser Fahrzeuge',
          value: 3,
        },
        {
          name: 'Schiffsausrüstung',
          value: 4,
        },
        {
          name: 'Interoperabilität des Eisenbahnsystems',
          value: 5,
        },
        {
          name: 'Keine der oben genannten',
          value: 6,
        },
      ],
      type: 'MULTIPLE_CHOICE',
      category: 3,
    },
    {
      id: 18,
      title:
        'Wird Ihr KI-System in einem der folgenden Produkte oder Sektoren verwendet?',
      description:
        'Es gibt einige Anwendungsfälle, die immer als hochriskant eingestuft werden, wenn sie als Sicherheitskomponente bereits regulierter Produkte fungieren oder wenn das System selbst ein bereits reguliertes Produkt ist (siehe Artikel 6 oder Anhang I der KI Verordnung)',
      options: [
        {
          name: 'Maschinen',
          value: 0,
        },
        {
          name: 'Spielzeuge',
          value: 1,
        },
        {
          name: 'Sportboote und Wassermotorräder',
          value: 2,
        },
        {
          name: 'Aufzüge und Sicherheitskomponenten von Aufzügen',
          value: 3,
        },
        {
          name: 'Geräte und Schutzsysteme in explosionsgefährdeten Bereichen',
          value: 4,
        },
        {
          name: 'Funkanlagen',
          value: 5,
        },
        {
          name: 'Druckgeräte',
          value: 6,
        },
        {
          name: 'Seilbahnanlagen',
          value: 7,
        },
        {
          name: 'Persönliche Schutzausrüstungen',
          value: 8,
        },
        {
          name: 'Geräte zur Verbrennung von Gas',
          value: 9,
        },
        {
          name: 'Medizinprodukte',
          value: 10,
        },
        {
          name: 'In-vitro-Diagnostika',
          value: 11,
        },
        {
          name: 'Keine der oben genannten',
          value: 12,
        },
      ],
      type: 'MULTIPLE_CHOICE',
      category: 3,
    },
    {
      id: 19,
      title: 'Müssen Sie eine Konformitätsbewertung durch Dritte durchlaufen?',
      description:
        'Wenn Sie für Ihr reguliertes Produkt aus der vorherigen Frage eine Konformitätsbewertung durch Dritte durchführen müssen, müssen Sie wahrscheinlich auch die Anforderungen der KI Verordnung erfüllen. Bitte überprüfen Sie die geltenden Verordnungen für Ihre Produktkategorie (siehe Anhang I, Abschnitt A der KI Verordnung).',
      options: [
        {
          name: 'Ja, eine Konformitätsbewertung ist erforderlich',
          value: 0,
        },
        {
          name: 'Nein, ist sie nicht',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 20,
      title: 'Wird Ihr KI-System in einem der folgenden Bereiche verwendet?',
      description:
        'Es gibt einige Anwendungsfälle, die als hochriskant eingestuft werden, da sie Menschen oder deren Grundrechte erheblich beeinträchtigen können, wenn das KI-System fehlschlägt oder missbraucht wird (siehe Artikel 6 oder Anhang III der KI Verordnung)',
      options: [
        {
          name: 'Biometrie, einschließlich Emotionserkennung',
          value: 0,
        },
        {
          name: 'Kritische Infrastrukturen, einschließlich digitaler Infrastruktur, Straßenverkehr und Energieversorgung',
          value: 1,
        },
        {
          name: 'Bildung, einschließlich der Bewertung von Lernergebnissen und dem Zugang zu Bildung',
          value: 2,
        },
        {
          name: 'Personalwesen, einschließlich Rekrutierung, Aufgabenverteilung und Entscheidungen über Beförderung oder Kündigung',
          value: 3,
        },
        {
          name: 'Wesentliche private und öffentliche Dienstleistungen oder Leistungen, einschließlich Finanzen, Versicherungen, Gesundheitswesen und Notdienste',
          value: 4,
        },
        {
          name: 'Strafverfolgung',
          value: 5,
        },
        {
          name: 'Migration, Asyl und Grenzkontrollen, einschließlich Sicherheitsrisikobewertungen und Prüfung von Aufenthaltserlaubnissen',
          value: 6,
        },
        {
          name: 'Justiz und demokratische Prozesse, einschließlich der Interpretation von Fakten und Gesetzen sowie Wahlen',
          value: 7,
        },
        {
          name: 'Keine der oben genannten',
          value: 8,
        },
      ],
      type: 'MULTIPLE_CHOICE',
      category: 3,
    },
    {
      id: 21,
      title: 'Wird das KI-System für folgende Zwecke verwendet?',
      description:
        'KI-Systeme werden verwendet für:\n\n- Biometrische Fernidentifizierung (ausgenommen Systeme zur biometrischen Verifizierung von Personen)\n- Biometrische Kategorisierung unter Verwendung sensibler Merkmale von Personen\n- Emotionserkennung',
      options: [
        { name: 'Ja', value: 0 },
        {
          name: 'Nein',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 22,
      title: 'Wird das KI-System für folgende Zwecke verwendet?',
      description:
        'KI-Systeme werden als Sicherheitskomponente für das Management und den Betrieb verwendet von:\n\n- kritischer digitaler Infrastruktur\n- Straßenverkehr\n- Versorgung mit Wasser, Gas, Heizung oder Strom',
      options: [
        { name: 'Ja', value: 0 },
        {
          name: 'Nein',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 23,
      title: 'Wird das KI-System für folgende Zwecke verwendet?',
      description:
        'KI-Systeme werden verwendet für:\n\n- Bestimmung des Zugangs und der Zulassung zu Bildungseinrichtungen\n- Bewertung von Lernergebnissen\n- Bewertung des geeigneten Bildungsniveaus, das jemand erhalten wird\n- Überwachung und Erkennung von unerlaubtem Verhalten von Studierenden während einer Prüfung',
      options: [
        { name: 'Ja', value: 0 },
        {
          name: 'Nein',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 24,
      title:
        'Bitte geben Sie den Bereich an, in dem das KI-System verwendet wird',
      description: '',
      options: [
        { name: 'Rekrutierung', value: 0 },
        {
          name: 'Beschäftigung / Arbeitsverhältnis',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 25,
      title:
        'Bitte geben Sie den Bereich an, in dem das KI-System verwendet wird',
      description: '',
      options: [
        { name: 'Banken / Finanzen', value: 0 },
        {
          name: 'Versicherungen',
          value: 1,
        },
        {
          name: 'Öffentliche Dienstleistungen',
          value: 2,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 26,
      title: 'Wird das KI-System für folgende Zwecke verwendet?',
      description:
        'KI-Systeme werden verwendet:\n\n- zur Bewertung des Risikos, dass eine Person Opfer einer Straftat wird\n- als Lügendetektor oder ähnliches\n- zur Bewertung der Zuverlässigkeit von Beweisen bei der Untersuchung von Straftaten\n- zur Bewertung des Risikos, dass eine Person eine Straftat begeht\n- zur Bewertung von Persönlichkeitsmerkmalen basierend auf früherem kriminellem Verhalten\n- zur Erstellung von Profilen von Personen bei der Untersuchung von Straftaten',
      options: [
        { name: 'Ja', value: 0 },
        {
          name: 'Nein',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 27,
      title: 'Wird das KI-System für folgende Zwecke verwendet?',
      description:
        'KI-Systeme werden verwendet für:\n\n- als Lügendetektor oder ähnliches\n- zur Bewertung des Sicherheitsrisikos einer Person beim Betreten eines EU-Staates\n- zur Bewertung des Risikos irregulärer Migration einer Person beim Betreten eines EU-Staates\n- zur Bewertung des Gesundheitsrisikos einer Person beim Betreten eines EU-Staates\n- zur Bewertung von Asylanträgen, Visa oder Aufenthaltserlaubnissen und damit verbundenen Beschwerden\n- zur Erkennung, Wiedererkennung oder Identifizierung von Personen (nicht einschließlich der Verifizierung von Reisedokumenten)',
      options: [
        { name: 'Ja', value: 0 },
        {
          name: 'Nein',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 28,
      title: 'Wird das KI-System für folgende Zwecke verwendet?',
      description:
        'KI-Systeme werden verwendet für:\n\n- zur Erforschung und Interpretation des Rechts in Bezug auf einen bestimmten Sachverhalt oder auf ähnliche Weise in alternativen Streitbeilegungen\n- zur Beeinflussung des Ergebnisses einer Wahl oder eines Referendums oder des Wahlverhaltens von Personen (nicht einschließlich KI-Systeme, die für logistische oder administrative Zwecke verwendet werden)',
      options: [
        { name: 'Ja', value: 0 },
        {
          name: 'Nein',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 29,
      title: 'Wird das KI-System für folgende Zwecke verwendet?',
      description:
        'KI-Systeme werden während des Rekrutierungs- und Auswahlprozesses verwendet, einschließlich:\n\n- Schalten gezielter Stellenanzeigen\n- Analyse und Filterung von Bewerbungen\n- Bewertung von Kandidaten',
      options: [
        { name: 'Ja', value: 0 },
        {
          name: 'Nein',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 30,
      title: 'Wird das KI-System für folgende Zwecke verwendet?',
      description:
        'KI-Systeme werden verwendet, um:\n\n- Entscheidungen über Arbeitsbedingungen zu treffen\n- Entscheidungen über Beförderungen zu treffen\n- Entscheidungen über Kündigungen zu treffen\n- Aufgaben basierend auf individuellem Verhalten oder Eigenschaften von Personen zuzuweisen\n- die arbeitsbezogene Leistung und das Verhalten von Personen zu überwachen und zu bewerten',
      options: [
        { name: 'Ja', value: 0 },
        {
          name: 'Nein',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 31,
      title: 'Wird das KI-System für folgende Zwecke verwendet?',
      description:
        'KI-Systeme werden verwendet, um die Kreditwürdigkeit einer Person zu bewerten oder einen Kreditscore zu erstellen',
      options: [
        { name: 'Ja', value: 0 },
        {
          name: 'Nein',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 32,
      title: 'Wird das KI-System für folgende Zwecke verwendet?',
      description:
        'KI-Systeme werden für Risikobewertungen und Preisgestaltungen verwendet bezüglich:\n\n- Krankenversicherungen\n- Lebensversicherungen',
      options: [
        { name: 'Ja', value: 0 },
        {
          name: 'Nein',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 33,
      title: 'Wird das KI-System für folgende Zwecke verwendet?',
      description:
        'KI-Systeme werden verwendet, um:\n\n- die Berechtigung von Personen für wesentliche öffentliche Leistungen und Dienste zu bewerten\n- solche Leistungen und Dienste zu gewähren, zu kürzen, zu widerrufen oder zurückzufordern\n- Notdienste zu entsenden, zu bewerten und zu priorisieren',
      options: [
        { name: 'Ja', value: 0 },
        {
          name: 'Nein',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },

    {
      id: 34,
      title:
        'Können Sie argumentieren, dass das Hochrisiko-KI-System nicht schädlich ist?',
      description:
        'Ein Hochrisiko-KI-System wird nicht als erheblich schädlich für die Gesundheit, Sicherheit oder Grundrechte von Menschen betrachtet, wenn mindestens eine dieser Bedingungen erfüllt ist:\n\n- Das KI-System führt eine eng begrenzte prozedurale Aufgabe aus\n- Das KI-System verbessert nur das Ergebnis einer vorherigen menschlichen Tätigkeit\n- Das KI-System erkennt nur Abweichungen von bisherigen Entscheidungsmustern und ersetzt oder beeinflusst nicht die menschliche Beurteilung\n- Das KI-System ist dazu bestimmt, eine vorbereitende Aufgabe für eine Bewertung im Rahmen der in Anhang III aufgeführten Anwendungsfälle durchzuführen',
      options: [
        {
          name: 'Es ist nicht schädlich, obwohl es unter die Hochrisikokategorie fällt (d.h. eine der oben genannten Bedingungen sind erfüllt)',
          value: 0,
        },
        {
          name: 'Es könnte schädlich sein, wenn das System versagt oder missbraucht wird',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 3,
    },
    {
      id: 35,
      title:
        'Kann Ihr KI-System für einen der folgenden Zwecke verwendet werden?',
      description:
        'Es gibt einige Anwendungsfälle, die Transparenzverpflichtungen unterliegen (siehe Artikel 50 der KI Verordnung).',
      options: [
        {
          name: 'Interagiert mit Menschen',
          value: 0,
        },
        {
          name: 'Generiert synthetische Inhalte (z.B. Text, Video, Audio, Bilder)',
          value: 1,
        },
        {
          name: 'Keine dieser Optionen',
          value: 2,
        },
      ],
      type: 'MULTIPLE_CHOICE',
      category: 4,
    },
    {
      id: 36,
      title:
        'Kann Ihr KI-System für einen der folgenden Zwecke verwendet werden?',
      description:
        'Es gibt einige Anwendungsfälle, die Transparenzverpflichtungen unterliegen (siehe Artikel 50 der KI Verordnung).',
      options: [
        {
          name: 'Erkennt die Emotionen einer Person oder kategorisiert Biometriken',
          value: 0,
        },
        {
          name: 'Generiert oder manipuliert Inhalte (Video, Audio, Bilder), die für Deepfakes verwendet werden können',
          value: 1,
        },
        {
          name: 'Generiert oder manipuliert Texte, die zur Information der Öffentlichkeit verwendet werden',
          value: 2,
        },
        {
          name: 'Keine dieser Optionen',
          value: 3,
        },
      ],
      type: 'MULTIPLE_CHOICE',
      category: 4,
    },
    {
      id: 37,
      title: 'Ihr Ergebnis:',
      description: '',
      options: [],
      type: 'SINGLE_CHOICE',
      category: 5,
    },
  ];

export let role: RiskClassRoleEnum;
export let risk: RiskClassCategoryEnum | undefined;
export let specialCase: RiskClassSpecialCase | undefined;
export const riskClassHistory: Array<[number, RiskClassCategoryEnum]> = [];
function calculateRole(answers: Answer[]): RiskClassRoleEnum {
  if (
    (answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(0) &&
      answers[3]?.values.includes(1) &&
      answers[4]?.values.includes(1) &&
      answers[5]?.values.includes(1)) ||
    (answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(0) &&
      answers[3]?.values.includes(1) &&
      answers[4]?.values.includes(1) &&
      answers[5]?.values.includes(1))
  ) {
    role = RiskClassRoleEnum.Importer;
  }
  if (
    (answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(0) &&
      answers[3]?.values.includes(1) &&
      answers[4]?.values.includes(1) &&
      answers[5]?.values.includes(1)) ||
    (answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(0) &&
      answers[3]?.values.includes(1) &&
      answers[4]?.values.includes(1) &&
      answers[5]?.values.includes(1))
  ) {
    if (role === RiskClassRoleEnum.Importer) {
      role = RiskClassRoleEnum.ImporterAndDistributor;
    } else role = RiskClassRoleEnum.Distributor;
    return role;
  }
  if (
    (answers[0]?.values.includes(1) && answers[1]?.values.includes(0)) ||
    (answers[0]?.values.includes(1) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(0)) ||
    (answers[0]?.values.includes(1) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(1)) ||
    (answers[0]?.values.includes(1) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(0)) ||
    (answers[0]?.values.includes(0) && answers[1]?.values.includes(0)) ||
    (answers[0]?.values.includes(0) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(0)) ||
    (answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(0) &&
      answers[3]?.values.includes(0)) ||
    (answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(0) &&
      answers[3]?.values.includes(1) &&
      answers[4]?.values.includes(0)) ||
    (answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(0) &&
      answers[3]?.values.includes(0)) ||
    (answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(0) &&
      answers[3]?.values.includes(1) &&
      answers[4]?.values.includes(1) &&
      answers[5]?.values.includes(0)) ||
    (answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(0) &&
      answers[3]?.values.includes(1) &&
      answers[4]?.values.includes(0)) ||
    (answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(0) &&
      answers[3]?.values.includes(1) &&
      answers[4]?.values.includes(1) &&
      answers[5]?.values.includes(0))
  ) {
    role = RiskClassRoleEnum.Provider;
  }
  if (
    answers[0]?.values.includes(2) ||
    (answers[0]?.values.includes(1) && answers[1]?.values.includes(0)) ||
    (answers[0]?.values.includes(1) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(0)) ||
    (answers[0]?.values.includes(1) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(1))
  ) {
    if (role === RiskClassRoleEnum.Provider) {
      role = RiskClassRoleEnum.ProviderAndDeployer;
    } else {
      role = RiskClassRoleEnum.Deployer;
    }
    return role;
  }
  if (!role) {
    role = RiskClassRoleEnum.OutOfScope;
  }
  return role;
}
const RESULT_PAGE = 37;
export const riskClassificationQuestionActions: {
  [key: number]: (answers: Answer[]) => number;
} = {
  0: answers => {
    if (answers[0]?.values.includes(2)) return 9;
    else return 1;
  },
  1: answers => {
    if (answers[0]?.values.includes(3) && answers[1]?.values.includes(0))
      return 3;
    else if (
      (answers[0]?.values.includes(0) || answers[0]?.values.includes(1)) &&
      answers[1]?.values.includes(0)
    )
      return 9;
    else return 2;
  },
  2: answers => {
    if (answers[0]?.values.includes(0) || answers[0]?.values.includes(1))
      return 9;
    else if (
      answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(1)
    )
      return 8;
    else return 3;
  },
  5: () => {
    return 9;
  },
  // maybe needed in the future again
  // 7: answers => {
  //   if (
  //     answers[0]?.values.includes(3) &&
  //     answers[1]?.values.includes(1) &&
  //     answers[2]?.values.includes(1)
  //   )
  //     return 8;
  //   else if (
  //     (answers[0]?.values.includes(1) &&
  //       answers[1]?.values.includes(1) &&
  //       answers[2]?.values.includes(1) &&
  //       answers[6]?.values.includes(1) &&
  //       answers[7]?.values.includes(1)) ||
  //     (answers[0]?.values.includes(0) &&
  //       answers[1]?.values.includes(1) &&
  //       answers[2]?.values.includes(1) &&
  //       answers[7]?.values.includes(1)) ||
  //     (answers[0]?.values.includes(2) &&
  //       answers[6]?.values.includes(1) &&
  //       answers[7]?.values.includes(1))
  //   ) {
  //     role = RiskClassRoleEnum.OutOfScope;
  //     return RESULT_PAGE;
  //   } else {

  //     return 9;
  //   }
  // },
  8: answers => {
    if (answers[8]?.values.includes(1)) {
      role = RiskClassRoleEnum.OutOfScope;
      return RESULT_PAGE;
    } else {
      role = RiskClassRoleEnum.AuthorizedRepresentative;
      return 9;
    }
  },
  // Category exemptions
  9: answers => {
    if (!answers[8]) {
      role = calculateRole(answers);
    }
    if (!answers[9]?.values.includes(3)) {
      risk = RiskClassCategoryEnum.Minimal; // OtherExemption
      riskClassHistory.push([9, RiskClassCategoryEnum.Minimal]);
      return RESULT_PAGE;
    } else return 10;
  },
  11: answers => {
    if (!answers[11]?.values.includes(8)) {
      risk = RiskClassCategoryEnum.Prohibited;
      riskClassHistory.push([11, RiskClassCategoryEnum.Prohibited]);
      return RESULT_PAGE;
    }
    if (
      role === RiskClassRoleEnum.Deployer ||
      role === RiskClassRoleEnum.Importer ||
      role === RiskClassRoleEnum.ImporterAndDistributor ||
      role === RiskClassRoleEnum.Distributor
    ) {
      return 16;
    } else return 12;
  },
  // Category GPAI
  12: answers => {
    if (answers[12]?.values.includes(1)) return 16;
    else return 13;
  },
  13: answers => {
    if (answers[13]?.values.includes(1) && answers[10]?.values.includes(0))
      return 15;
    else if (answers[13]?.values.includes(0)) return 14;
    else {
      specialCase = RiskClassSpecialCase.GPAI;
      return 16;
    }
  },
  14: answers => {
    if (answers[14]?.values.includes(0) && answers[10]?.values.includes(0))
      return 15;
    else if (
      answers[14]?.values.includes(0) &&
      answers[10]?.values.includes(1)
    ) {
      specialCase = RiskClassSpecialCase.GPAI;
      return 16;
    } else {
      specialCase = RiskClassSpecialCase.SYSTEMIC_RISK_GPAI;
      return 16;
    }
  },
  15: answers => {
    if (answers[15]?.values.includes(0)) {
      specialCase = RiskClassSpecialCase.FREE_AND_OPEN_SOURCE_GPAI;
      return 16;
    } else {
      specialCase = RiskClassSpecialCase.GPAI;
      return 16;
    }
  },
  // Category: high risk ai system
  16: answers => {
    if (answers[16]?.values.includes(0)) {
      return 17;
    } else {
      return 20;
    }
  },
  17: answers => {
    if (!answers[17]?.values.includes(6) && answers[12]?.values.includes(1)) {
      risk = RiskClassCategoryEnum.HighExemptions; // HighRiskExemption
      riskClassHistory.push([17, RiskClassCategoryEnum.HighExemptions]);
      return RESULT_PAGE;
    } else if (
      !answers[17]?.values.includes(6) &&
      answers[12]?.values.includes(0) &&
      (role === RiskClassRoleEnum.Provider ||
        role === RiskClassRoleEnum.ProviderAndDeployer ||
        role === RiskClassRoleEnum.Deployer)
    ) {
      risk = RiskClassCategoryEnum.HighExemptions; // HighRiskExemption
      riskClassHistory.push([17, RiskClassCategoryEnum.HighExemptions]);

      return RESULT_PAGE;
    } else return 18;
  },
  18: answers => {
    if (answers[18]?.values.includes(12)) return 20;
    else return 19;
  },
  19: answers => {
    if (
      answers[19]?.values.includes(0) &&
      (role === RiskClassRoleEnum.Provider ||
        role === RiskClassRoleEnum.ProviderAndDeployer)
    ) {
      risk = RiskClassCategoryEnum.High;
      riskClassHistory.push([19, RiskClassCategoryEnum.High]);
      return 35;
    } else if (
      answers[19]?.values.includes(0) &&
      role === RiskClassRoleEnum.Deployer
    ) {
      risk = RiskClassCategoryEnum.High;
      riskClassHistory.push([19, RiskClassCategoryEnum.High]);
      return 36;
    } else if (answers[19]?.values.includes(0)) {
      risk = RiskClassCategoryEnum.High;
      riskClassHistory.push([19, RiskClassCategoryEnum.High]);
      return RESULT_PAGE;
    } else {
      risk = undefined;
      return 20;
    }
  },
  20: answers => {
    if (answers[20]?.values.includes(0)) {
      return 21;
    } else if (answers[20]?.values.includes(1)) {
      return 22;
    } else if (answers[20]?.values.includes(2)) {
      return 23;
    } else if (answers[20]?.values.includes(3)) {
      return 24;
    } else if (answers[20]?.values.includes(4)) {
      return 25;
    } else if (answers[20]?.values.includes(5)) {
      return 26;
    } else if (answers[20]?.values.includes(6)) {
      return 27;
    } else if (answers[20]?.values.includes(7)) {
      return 28;
    } else if (
      answers[20]?.values.includes(8) &&
      (role === RiskClassRoleEnum.Provider ||
        role === RiskClassRoleEnum.ProviderAndDeployer)
    )
      return 35;
    else if (
      answers[20]?.values.includes(8) &&
      role === RiskClassRoleEnum.Deployer
    )
      return 36;
    else {
      risk = RiskClassCategoryEnum.Minimal;
      riskClassHistory.push([20, RiskClassCategoryEnum.Minimal]);
      return RESULT_PAGE;
    }
  },
  // Extra Layer after Annex III High-Risk AI System Question
  21: answers => {
    if (answers[21]?.values.includes(0)) {
      return 34;
    } else if (
      answers[21]?.values.includes(1) &&
      (role === RiskClassRoleEnum.Provider ||
        role === RiskClassRoleEnum.ProviderAndDeployer)
    )
      return 35;
    else if (
      answers[21]?.values.includes(1) &&
      role === RiskClassRoleEnum.Deployer
    )
      return 36;
    else {
      risk = RiskClassCategoryEnum.Minimal;
      riskClassHistory.push([21, RiskClassCategoryEnum.Minimal]);
      return RESULT_PAGE;
    }
  },
  22: answers => {
    if (answers[22]?.values.includes(0)) {
      return 34;
    } else if (
      answers[22]?.values.includes(1) &&
      (role === RiskClassRoleEnum.Provider ||
        role === RiskClassRoleEnum.ProviderAndDeployer)
    )
      return 35;
    else if (
      answers[22]?.values.includes(1) &&
      role === RiskClassRoleEnum.Deployer
    )
      return 36;
    else {
      risk = RiskClassCategoryEnum.Minimal;
      riskClassHistory.push([22, RiskClassCategoryEnum.Minimal]);
      return RESULT_PAGE;
    }
  },
  23: answers => {
    if (answers[23]?.values.includes(0)) {
      return 34;
    } else if (
      answers[23]?.values.includes(1) &&
      (role === RiskClassRoleEnum.Provider ||
        role === RiskClassRoleEnum.ProviderAndDeployer)
    )
      return 35;
    else if (
      answers[23]?.values.includes(1) &&
      role === RiskClassRoleEnum.Deployer
    )
      return 36;
    else {
      risk = RiskClassCategoryEnum.Minimal;
      riskClassHistory.push([23, RiskClassCategoryEnum.Minimal]);
      return RESULT_PAGE;
    }
  },
  24: answers => {
    if (answers[24]?.values.includes(0)) {
      return 29;
    } else return 30;
  },
  25: answers => {
    if (answers[25]?.values.includes(0)) {
      return 31;
    } else if (answers[25]?.values.includes(1)) return 32;
    else return 33;
  },
  26: answers => {
    if (answers[26]?.values.includes(0)) {
      return 34;
    } else if (
      answers[26]?.values.includes(1) &&
      (role === RiskClassRoleEnum.Provider ||
        role === RiskClassRoleEnum.ProviderAndDeployer)
    )
      return 35;
    else if (
      answers[26]?.values.includes(1) &&
      role === RiskClassRoleEnum.Deployer
    )
      return 36;
    else {
      risk = RiskClassCategoryEnum.Minimal;
      riskClassHistory.push([26, RiskClassCategoryEnum.Minimal]);
      return RESULT_PAGE;
    }
  },
  27: answers => {
    if (answers[27]?.values.includes(0)) {
      return 34;
    } else if (
      answers[27]?.values.includes(1) &&
      (role === RiskClassRoleEnum.Provider ||
        role === RiskClassRoleEnum.ProviderAndDeployer)
    )
      return 35;
    else if (
      answers[27]?.values.includes(1) &&
      role === RiskClassRoleEnum.Deployer
    )
      return 36;
    else {
      risk = RiskClassCategoryEnum.Minimal;
      riskClassHistory.push([27, RiskClassCategoryEnum.Minimal]);
      return RESULT_PAGE;
    }
  },
  28: answers => {
    if (answers[28]?.values.includes(0)) {
      return 34;
    } else if (
      answers[28]?.values.includes(1) &&
      (role === RiskClassRoleEnum.Provider ||
        role === RiskClassRoleEnum.ProviderAndDeployer)
    )
      return 35;
    else if (
      answers[28]?.values.includes(1) &&
      role === RiskClassRoleEnum.Deployer
    )
      return 36;
    else {
      risk = RiskClassCategoryEnum.Minimal;
      riskClassHistory.push([28, RiskClassCategoryEnum.Minimal]);
      return RESULT_PAGE;
    }
  },
  29: answers => {
    if (answers[29]?.values.includes(0)) {
      return 34;
    } else if (
      answers[29]?.values.includes(1) &&
      (role === RiskClassRoleEnum.Provider ||
        role === RiskClassRoleEnum.ProviderAndDeployer)
    )
      return 35;
    else if (
      answers[29]?.values.includes(1) &&
      role === RiskClassRoleEnum.Deployer
    )
      return 36;
    else {
      risk = RiskClassCategoryEnum.Minimal;
      riskClassHistory.push([29, RiskClassCategoryEnum.Minimal]);
      return RESULT_PAGE;
    }
  },
  30: answers => {
    if (answers[30]?.values.includes(0)) {
      return 34;
    } else if (
      answers[30]?.values.includes(1) &&
      (role === RiskClassRoleEnum.Provider ||
        role === RiskClassRoleEnum.ProviderAndDeployer)
    )
      return 35;
    else if (
      answers[30]?.values.includes(1) &&
      role === RiskClassRoleEnum.Deployer
    )
      return 36;
    else {
      risk = RiskClassCategoryEnum.Minimal;
      riskClassHistory.push([30, RiskClassCategoryEnum.Minimal]);
      return RESULT_PAGE;
    }
  },
  31: answers => {
    if (answers[31]?.values.includes(0)) {
      return 34;
    } else if (
      answers[31]?.values.includes(1) &&
      (role === RiskClassRoleEnum.Provider ||
        role === RiskClassRoleEnum.ProviderAndDeployer)
    )
      return 35;
    else if (
      answers[31]?.values.includes(1) &&
      role === RiskClassRoleEnum.Deployer
    )
      return 36;
    else {
      risk = RiskClassCategoryEnum.Minimal;
      riskClassHistory.push([31, RiskClassCategoryEnum.Minimal]);
      return RESULT_PAGE;
    }
  },
  32: answers => {
    if (answers[32]?.values.includes(0)) {
      return 34;
    } else if (
      answers[32]?.values.includes(1) &&
      (role === RiskClassRoleEnum.Provider ||
        role === RiskClassRoleEnum.ProviderAndDeployer)
    )
      return 35;
    else if (
      answers[32]?.values.includes(1) &&
      role === RiskClassRoleEnum.Deployer
    )
      return 36;
    else {
      risk = RiskClassCategoryEnum.Minimal;
      riskClassHistory.push([32, RiskClassCategoryEnum.Minimal]);
      return RESULT_PAGE;
    }
  },
  33: answers => {
    if (answers[33]?.values.includes(0)) {
      return 34;
    } else if (
      answers[33]?.values.includes(1) &&
      (role === RiskClassRoleEnum.Provider ||
        role === RiskClassRoleEnum.ProviderAndDeployer)
    )
      return 35;
    else if (
      answers[33]?.values.includes(1) &&
      role === RiskClassRoleEnum.Deployer
    )
      return 36;
    else {
      risk = RiskClassCategoryEnum.Minimal;
      riskClassHistory.push([33, RiskClassCategoryEnum.Minimal]);
      return RESULT_PAGE;
    }
  },
  34: answers => {
    if (answers[34]?.values.includes(1)) {
      risk = RiskClassCategoryEnum.High;
      riskClassHistory.push([34, RiskClassCategoryEnum.High]);
      if (
        role === RiskClassRoleEnum.AuthorizedRepresentative ||
        role === RiskClassRoleEnum.Importer ||
        role === RiskClassRoleEnum.Distributor ||
        role === RiskClassRoleEnum.ImporterAndDistributor
      ) {
        return RESULT_PAGE;
      } else if (
        role === RiskClassRoleEnum.Provider ||
        role === RiskClassRoleEnum.ProviderAndDeployer
      )
        return 35;
      else if (role === RiskClassRoleEnum.Deployer) return 36;
    }
    if (
      role === RiskClassRoleEnum.Provider ||
      role === RiskClassRoleEnum.ProviderAndDeployer
    ) {
      risk = undefined;
      return 35;
    } else if (role === RiskClassRoleEnum.Deployer) {
      risk = undefined;
      return 36;
    } else {
      risk = RiskClassCategoryEnum.Minimal;
      riskClassHistory.push([34, RiskClassCategoryEnum.Minimal]);
      return RESULT_PAGE;
    }
  },
  35: answers => {
    if (
      answers[35]?.values.includes(2) &&
      role === RiskClassRoleEnum.Provider
    ) {
      if (!specialCase && !risk) {
        risk = RiskClassCategoryEnum.Minimal;
        riskClassHistory.push([35, RiskClassCategoryEnum.Minimal]);
      }
      return RESULT_PAGE;
    } else if (
      (answers[35]?.values.includes(0) || answers[35]?.values.includes(1)) &&
      role === RiskClassRoleEnum.Provider
    ) {
      if (
        risk === RiskClassCategoryEnum.High ||
        risk === RiskClassCategoryEnum.HighAndLimited
      ) {
        risk = RiskClassCategoryEnum.HighAndLimited; // LimitedRiskAISystemProvider
        riskClassHistory.push([35, RiskClassCategoryEnum.HighAndLimited]);
      } else {
        risk = RiskClassCategoryEnum.Limited; // LimitedRiskAISystemProvider
        riskClassHistory.push([35, RiskClassCategoryEnum.Limited]);
      }

      return RESULT_PAGE;
    } else return 36;
  },
  36: answers => {
    if (
      (answers[35]?.values.includes(0) || answers[35]?.values.includes(1)) &&
      answers[36]?.values.includes(3) &&
      role === RiskClassRoleEnum.ProviderAndDeployer
    ) {
      if (
        risk === RiskClassCategoryEnum.High ||
        risk === RiskClassCategoryEnum.HighAndLimited
      ) {
        risk = RiskClassCategoryEnum.HighAndLimited; // LimitedRiskAISystemProvider
        riskClassHistory.push([36, RiskClassCategoryEnum.HighAndLimited]);
      } else {
        risk = RiskClassCategoryEnum.Limited; // LimitedRiskAISystemProvider
        riskClassHistory.push([36, RiskClassCategoryEnum.Limited]);
      }
      return RESULT_PAGE;
    } else if (
      (answers[35]?.values.includes(0) || answers[35]?.values.includes(1)) &&
      !answers[36]?.values.includes(3) &&
      role === RiskClassRoleEnum.ProviderAndDeployer
    ) {
      if (
        risk === RiskClassCategoryEnum.High ||
        risk === RiskClassCategoryEnum.HighAndLimited
      ) {
        risk = RiskClassCategoryEnum.HighAndLimited; // LimitedRiskAISystemProvider and LimitedRiskAISystemDeployer
        riskClassHistory.push([36, RiskClassCategoryEnum.HighAndLimited]);
      } else {
        risk = RiskClassCategoryEnum.Limited; // LimitedRiskAISystemProvider and LimitedRiskAISystemDeployer
        riskClassHistory.push([36, RiskClassCategoryEnum.Limited]);
      }
      return RESULT_PAGE;
    } else if (
      (!answers[36]?.values.includes(3) &&
        role === RiskClassRoleEnum.Deployer) ||
      (answers[35]?.values.includes(2) &&
        !answers[36]?.values.includes(3) &&
        role === RiskClassRoleEnum.ProviderAndDeployer)
    ) {
      if (
        risk === RiskClassCategoryEnum.High ||
        risk === RiskClassCategoryEnum.HighAndLimited
      ) {
        risk = RiskClassCategoryEnum.HighAndLimited; //  LimitedRiskAISystemDeployer
        riskClassHistory.push([36, RiskClassCategoryEnum.HighAndLimited]);
      } else {
        risk = RiskClassCategoryEnum.Limited; // LimitedRiskAISystemDeployer
        riskClassHistory.push([36, RiskClassCategoryEnum.Limited]);
      }
      return RESULT_PAGE;
    } else {
      if (!specialCase && !risk) {
        risk = RiskClassCategoryEnum.Minimal;
        riskClassHistory.push([36, RiskClassCategoryEnum.Minimal]);
      }
      return RESULT_PAGE;
    }
  },
};

export function clearRisk(previousRisk?: RiskClassCategoryEnum) {
  if (previousRisk) {
    risk = previousRisk;
  } else {
    risk = undefined;
  }
}
export function clearSpecialCase() {
  specialCase = undefined;
}
