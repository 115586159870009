import { useState, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Box, CircularProgress } from '@mui/material';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

interface PdfViewerProps {
  pdfData: string;
  currentPage: number;
  onPageLoad: (totalPages: number) => void;
}

const PdfViewer: React.FC<PdfViewerProps> = ({
  pdfData,
  currentPage,
  onPageLoad,
}) => {
  const [loading, setLoading] = useState(true);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    onPageLoad(numPages);
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Document
        file={`data:application/pdf;base64,${pdfData}`}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={error => console.error('Error loading PDF:', error)}
        loading={<CircularProgress />}
      >
        <Page
          pageNumber={currentPage}
          renderTextLayer={true}
          renderAnnotationLayer={true}
          loading={<CircularProgress />}
          onLoadSuccess={() => setLoading(false)}
          width={containerWidth}
        />
      </Document>
    </Box>
  );
};

export default PdfViewer;
