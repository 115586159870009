import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import CollapsableGridView, { GridCategory } from './CollapsableGridView';
import { gql, useQuery } from '@apollo/client';
import { useProject } from '../contexts/project';
import SearchIcon from '@mui/icons-material/Search';
import {
  AllDocumentationsQuery,
  DocumentationScopeEnum,
  DocumentationSummaryFragment,
} from '../__generated__/gql/graphql';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { colorThemes } from '../theme';
import { useResetDocumentState } from './documentation_view/DocumentationPageStates';
import ExportedDocumentList from './ExportedDocumentList';
import { toast } from 'react-toastify';

const GET_DOCUMENTATIONS = gql(`
  query allDocumentations($projectId: String, $hasProject: Boolean!) {
    organizationalDocs: allDocumentations {
      ...DocumentationSummary
    }
    projectDocs: allDocumentations(projectId: $projectId) @include(if: $hasProject) {
      ...DocumentationSummary
    }
    experimentDocs: allDocumentations(
      projectId: $projectId, 
      documentationScope: EXPERIMENT
    ) @include(if: $hasProject) {
      ...DocumentationSummary
    }
  }
  fragment DocumentationSummary on Documentation {
    id
    name
    documentationScope
    project {
      title
    }
  }
`);

export default function DocumentationOverviewPage() {
  const { project } = useProject();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [documents, setDocuments] = useState<DocumentationSummaryFragment[]>();

  const { loading } = useQuery<AllDocumentationsQuery>(GET_DOCUMENTATIONS, {
    variables: {
      projectId: project?.id || null,
      hasProject: !!project?.id,
    },
    onCompleted: data => {
      if (data) {
        const allDocs = [
          ...(data.organizationalDocs || []),
          ...(data.projectDocs || []),
          ...(data.experimentDocs || []),
        ];
        setDocuments(allDocs as DocumentationSummaryFragment[]);
      }
    },
  });

  const filteredDocuments = documents?.filter(doc =>
    doc.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const organizationalDocuments = filteredDocuments?.filter(
    doc => doc.documentationScope === DocumentationScopeEnum.Organization
  );
  const projectDocuments = filteredDocuments?.filter(
    doc => doc.documentationScope === DocumentationScopeEnum.Project
  );
  const experimentalDocuments = filteredDocuments?.filter(
    doc => doc.documentationScope === DocumentationScopeEnum.Experiment
  );

  const handleDelete = (documentId: string) => {
    setDocuments(prev => prev?.filter(doc => doc.id !== documentId));
    toast.success('Documentation deleted successfully');
  };

  return (
    <>
      <Box sx={{ paddingRight: '1rem', paddingBottom: '3rem' }}>
        <Box
          sx={{
            marginBottom: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h4">Documentations</Typography>
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <TextField
              id="search"
              sx={{ width: 400 }}
              size="small"
              onChange={e => setSearchTerm(e.target.value)}
              placeholder="Search with document title or tags ..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <CreateNewDocumentButton />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CollapsableGridView
            loading={loading}
            documents={organizationalDocuments || []}
            gridCategory={GridCategory.ORG}
            onDelete={handleDelete}
          />
          <CollapsableGridView
            loading={loading}
            documents={projectDocuments || []}
            gridCategory={GridCategory.PROJECT}
            onDelete={handleDelete}
          />
          <CollapsableGridView
            loading={loading}
            documents={experimentalDocuments || []}
            gridCategory={GridCategory.EXPERIMENT}
            onDelete={handleDelete}
          />
        </Box>
        <ExportedDocumentList />
      </Box>
    </>
  );
}

function CreateNewDocumentButton() {
  const navigate = useNavigate();
  const resetAll = useResetDocumentState();
  return (
    <Button
      sx={{
        ':hover': {
          cursor: 'pointer',
          backgroundColor: colorThemes.YELLOW_600,
        },
        fontWeight: 'bold',
        paddingX: '1rem',
        gap: '0.5rem',
        borderRadius: '6px',
        color: 'black',
        backgroundColor: colorThemes.YELLOW_400,
      }}
      onClick={() => {
        resetAll();
        navigate('/documentation/?view=template');
      }}
    >
      <AddIcon sx={{ color: 'black' }} />
      Add Document
    </Button>
  );
}
