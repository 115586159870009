import {
  RiskClassCategoryEnum,
  RiskClassRoleEnum,
} from '../__generated__/gql/graphql';
import { Answer } from './RiskClassification';
import { QuestionnaireQuestion } from './RiskClassificationQuestions';

export const possibleRiskOnQuestion: {
  [key: number]: RiskClassCategoryEnum[];
} = {};

export const englishValidationQuestionnaireQuestions: QuestionnaireQuestion[] =
  [
    {
      id: 0,
      title: 'Is your system seen as an AI system under the EU AI Act?',
      description: '',
      options: [
        {
          name: 'Yes',
          value: 0,
        },
        {
          name: 'I don’t know',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 5,
    },
    {
      id: 1,
      title:
        'Is the considered system machine-based and influencing physical or virtual environments?',
      description:
        'Explainer:\n\nThe system is running on a machine, such as computers.\n(Hence, this is not including biological- or chemical-based systems, such as molecular computers)',
      options: [
        {
          name: 'Yes',
          value: 0,
        },
        {
          name: 'No',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 5,
    },
    {
      id: 2,
      title: 'Does the system have self-learning capabilities?',
      description:
        'Explainer:\n\nThe system has the capability to learn while it is in use, which could lead to changes in the system or its behaviour. Hence, the system exhibits adaptiveness.',
      options: [
        {
          name: 'Yes',
          value: 0,
        },
        {
          name: 'No',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 5,
    },
    {
      id: 3,
      title: 'Is the system autonomous?',
      description:
        'Explainer:\n\nThe system is to a certain extent autonomous, meaning that it can operate without human intervention or involvement.',
      options: [
        {
          name: 'Yes',
          value: 0,
        },
        {
          name: 'No',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 5,
    },
    {
      id: 4,
      title:
        'Does the system follow explicitly defined or implicit objectives?',
      description:
        'Explainer:\n\nThe system follows explicitly defined or implicit objectives. These could be potentially different to the intended purpose of the system.',
      options: [
        {
          name: 'Yes',
          value: 0,
        },
        {
          name: 'No',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 5,
    },
    {
      id: 5,
      title: 'Does the system have inference capabilities?',
      description:
        'Explainer:\n\nThe system can independently draw conclusions from data to make predictions, suggestions or decisions, or to create content. This could also include the capability of the system to derive models or algorithms from data. This capability goes beyond simple data processing, and is enabled through the utilisation of machine learning, logic-based or knowledge-based approaches. \n(This is not including systems that are exclusively based on human-defined rules for the automatic execution of operations)',
      options: [
        {
          name: 'Yes',
          value: 0,
        },
        {
          name: 'No',
          value: 1,
        },
      ],
      type: 'SINGLE_CHOICE',
      category: 5,
    },
    {
      id: 6,
      title: '',
      description: '',
      options: [],
      type: 'SINGLE_CHOICE',
      category: 5,
    },
  ];

export const germanValidationQuestionnaireQuestions: QuestionnaireQuestion[] = [
  {
    id: 0,
    title:
      'Wird Ihr System gemäß der KI Verordnung als ein KI-System angesehen?',
    description: '',
    options: [
      {
        name: 'Ja',
        value: 0,
      },
      {
        name: 'Ich weiß es nicht',
        value: 1,
      },
    ],
    type: 'SINGLE_CHOICE',
    category: 5,
  },
  {
    id: 1,
    title:
      'Ist das betrachtete System maschinengestützt und beeinflusst es physische oder virtuelle Umgebungen?',
    description:
      'Erklärung:\n\nDas System läuft auf einer Maschine, wie z.B. einem Computer.\n(Hiermit sind also keine biologischen oder chemischen Systeme, wie molekulare Computer, gemeint)',
    options: [
      {
        name: 'Ja',
        value: 0,
      },
      {
        name: 'Nein',
        value: 1,
      },
    ],
    type: 'SINGLE_CHOICE',
    category: 5,
  },
  {
    id: 2,
    title: 'Ist das System fähig eigenständig zu Lernen?',
    description:
      'Erklärung:\n\nDas System hat die Fähigkeit, während seiner Nutzung zu lernen, was zu Änderungen im System oder seinem Verhalten führen kann. Das System zeigt somit Anpassungsfähigkeit.',
    options: [
      {
        name: 'Ja',
        value: 0,
      },
      {
        name: 'Nein',
        value: 1,
      },
    ],
    type: 'SINGLE_CHOICE',
    category: 5,
  },
  {
    id: 3,
    title: 'Ist das System autonom?',
    description:
      'Erklärung:\n\nDas System ist für unterschiedliche Grade der Autonomie ausgelegt, was bedeutet, dass es ohne menschliches Eingreifen oder Beteiligung arbeiten kann.',
    options: [
      {
        name: 'Ja',
        value: 0,
      },
      {
        name: 'Nein',
        value: 1,
      },
    ],
    type: 'SINGLE_CHOICE',
    category: 5,
  },
  {
    id: 4,
    title: 'Folgt das System explizit definierten oder impliziten Zielen?',
    description:
      'Erklärung:\n\nDas System folgt explizit definierten oder impliziten Zielen. Diese könnten sich potenziell zum beabsichtigten Zweck des Systems unterscheiden.',
    options: [
      {
        name: 'Ja',
        value: 0,
      },
      {
        name: 'Nein',
        value: 1,
      },
    ],
    type: 'SINGLE_CHOICE',
    category: 5,
  },
  {
    id: 5,
    title: 'Hat das System die Fähigkeit der Schlussfolgerung?',
    description:
      'Erklärung:\n\nDas System kann unabhängig Schlussfolgerungen aus Daten ziehen, um Vorhersagen, Vorschläge oder Entscheidungen zu treffen oder Inhalte zu erstellen. Dies könnte auch die Fähigkeit des Systems umfassen, Modelle oder Algorithmen aus Daten abzuleiten. Diese Fähigkeit geht über einfache Datenverarbeitung hinaus und wird durch die Nutzung von maschinellem Lernen, logikbasierten oder wissensbasierten Ansätzen ermöglicht. \n(Dies schließt Systeme aus, die ausschließlich auf von Menschen definierten Regeln für die automatische Ausführung von Operationen basieren)',
    options: [
      {
        name: 'Ja',
        value: 0,
      },
      {
        name: 'Nein',
        value: 1,
      },
    ],
    type: 'SINGLE_CHOICE',
    category: 5,
  },
  {
    id: 6,
    title: '',
    description: '',
    options: [],
    type: 'SINGLE_CHOICE',
    category: 5,
  },
];

const RESULT_PAGE = 6;

export let role: RiskClassRoleEnum | undefined;
export const validationQuestionActions: {
  [key: number]: (answers: Answer[]) => number;
} = {
  0: answers => {
    if (answers[0]?.values.includes(0)) {
      role = undefined;
      return RESULT_PAGE;
    } else return 1;
  },
  1: answers => {
    if (answers[1]?.values.includes(1)) {
      role = RiskClassRoleEnum.OutOfScope;
      return RESULT_PAGE;
    } else return 2;
  },
  2: () => {
    return 3;
  },
  3: answers => {
    if (answers[3]?.values.includes(1)) {
      role = RiskClassRoleEnum.OutOfScope;
      return RESULT_PAGE;
    } else return 4;
  },
  4: answers => {
    if (answers[4]?.values.includes(1)) {
      role = RiskClassRoleEnum.OutOfScope;
      return RESULT_PAGE;
    } else return 5;
  },
  5: answers => {
    if (answers[5]?.values.includes(1)) {
      role = RiskClassRoleEnum.OutOfScope;
      return RESULT_PAGE;
    } else {
      role = undefined;
    }
    return RESULT_PAGE;
  },
};
