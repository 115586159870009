import {
  Box,
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  ButtonGroup,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useState } from 'react';
import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import TrashIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  TabContext,
  TabPanel,
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@mui/lab';
import { GET_AUDITS } from './AuditView';
import {
  AuditStatusEnum,
  AuditViewItemsQuery,
  GetAuditFindingsQuery,
  RequirementAuditStatusEnum,
} from '../__generated__/gql/graphql';
import UserTag from '../components/UserTag';
import MenuButton from '../components/MenuButton';
import EditableTypography from '../project_view/EditableTypography';
import { formatGraphqlEnumToReadableText } from '../utils/stringManipulation';
import { useNavigate } from 'react-router-dom';
import { getAuditStatusIcon } from './AuditStatusIcon';

const DELETE_AUDIT = gql`
  mutation DeleteAudit($auditId: ID!) {
    deleteGovernanceAudit(auditId: $auditId) {
      audit {
        id
      }
    }
  }
`;

const UPDATE_AUDIT = gql`
  mutation UpdateAudit(
    $auditId: ID!
    $name: String
    $description: String
    $status: String
  ) {
    updateGovernanceAudit(
      auditId: $auditId
      name: $name
      description: $description
      status: $status
    ) {
      audit {
        id
        name
        description
        status
        auditors {
          id
          name
          email
        }
      }
    }
  }
`;

const GET_AUDIT_FINDINGS = gql`
  query GetAuditFindings($auditId: String!) {
    governanceAudit(auditId: $auditId) {
      id
      auditStatuses {
        id
        sections {
          status
          comments
          evidenceNotes
        }
        materializedRequirement {
          id
          title
          description
        }
        requirementStatus {
          id
        }
      }
    }
  }
`;

export interface AuditDetailDrawerProps {
  showSidebar: boolean;
  setShowSidebar: (show: boolean) => void;
  selectedAudit: NonNullable<
    NonNullable<AuditViewItemsQuery['allGovernanceAudits']>[number]
  >;
}

const getHighestSeverityStatus = (sections: any[]) => {
  const severityOrder = {
    [RequirementAuditStatusEnum.NonCompliant]: 3,
    [RequirementAuditStatusEnum.Compliant]: 2,
    [RequirementAuditStatusEnum.NotReviewed]: 1,
  };

  return sections.reduce((highest, section) => {
    const currentSeverity =
      severityOrder[section.status as RequirementAuditStatusEnum] || 0;
    const highestSeverity =
      severityOrder[highest as RequirementAuditStatusEnum] || 0;
    return currentSeverity > highestSeverity ? section.status : highest;
  }, RequirementAuditStatusEnum.NotReviewed);
};

export default function AuditDetailDrawer({
  setShowSidebar,
  selectedAudit,
}: AuditDetailDrawerProps) {
  const navigate = useNavigate();
  const [isEditable, setIsEditable] = useState(false);
  const [name, setName] = useState(selectedAudit.name);
  const [description, setDescription] = useState(selectedAudit.description);
  const [status, setStatus] = useState(selectedAudit.status);
  const [tab, setTab] = useState('0');
  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] =
    useState<null | HTMLElement>(null);

  const [deleteAudit] = useMutation(DELETE_AUDIT, {
    variables: { auditId: selectedAudit.id },
    refetchQueries: [{ query: GET_AUDITS }],
    onCompleted: () => {
      toast.success('Audit deleted successfully');
      setShowSidebar(false);
    },
    onError: error => {
      toast.error(`Error deleting audit: ${error.message}`);
    },
  });

  const [updateAudit] = useMutation(UPDATE_AUDIT, {
    variables: {
      auditId: selectedAudit.id,
      name,
      description,
      status,
    },
    onCompleted: () => {
      toast.success('Audit updated successfully');
      setIsEditable(false);
    },
    onError: error => {
      toast.error(`Error updating audit: ${error.message}`);
    },
  });

  const { data: findingsData } = useSuspenseQuery<GetAuditFindingsQuery>(
    GET_AUDIT_FINDINGS,
    {
      variables: { auditId: selectedAudit.id },
    }
  );

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const resetState = () => {
    setName(selectedAudit.name);
    setDescription(selectedAudit.description || '');
    setStatus(selectedAudit.status);
  };

  const handleStatusChange = (newStatus: AuditStatusEnum) => {
    setStatus(newStatus);
    updateAudit({
      variables: {
        auditId: selectedAudit.id,
        status: newStatus,
      },
    });
  };

  const handlePerformAudit = () => {
    setShowSidebar(false);
    navigate(`/framework/${selectedAudit.frameworkId}/${selectedAudit.id}`);
  };

  const renderStatusActions = () => {
    switch (status) {
      case AuditStatusEnum.NotStarted:
        return (
          <Button
            variant="outlined"
            onClick={() => handleStatusChange(AuditStatusEnum.InProgress)}
          >
            Start Audit
          </Button>
        );
      case AuditStatusEnum.InProgress:
        return (
          <>
            <Button
              variant="outlined"
              onClick={() => handleStatusChange(AuditStatusEnum.Completed)}
            >
              Complete Audit
            </Button>
            <Button variant="outlined" onClick={handlePerformAudit}>
              Perform Audit
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Grid
        sx={{
          width: '100%',
          py: '30px',
          display: 'flex',
          justifyContent: 'start',
        }}
      >
        <Box sx={{ px: '30px', width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              pb: '10px',
            }}
          >
            <Box display="flex" gap={1} flexWrap="wrap">
              <ButtonGroup
                variant="outlined"
                sx={{
                  '& .MuiButton-root': {
                    whiteSpace: 'nowrap',
                    minWidth: 'auto',
                    px: 2,
                  },
                }}
              >
                {renderStatusActions()}
                <MenuButton
                  variant="outlined"
                  startIcon={<EditIcon style={{ color: 'white' }} />}
                  label={isEditable ? 'Cancel' : 'Edit'}
                  onClick={() => {
                    if (isEditable) {
                      resetState();
                    }
                    setIsEditable(!isEditable);
                  }}
                  items={[]}
                />
                {isEditable && (
                  <MenuButton
                    variant="outlined"
                    startIcon={<SaveIcon style={{ color: 'white' }} />}
                    label="Save"
                    onClick={() => updateAudit()}
                    items={[]}
                  />
                )}
                <Button
                  variant="outlined"
                  startIcon={<MoreVertIcon sx={{ color: 'white' }} />}
                  onClick={e => setMoreOptionsAnchorEl(e.currentTarget)}
                >
                  more options
                </Button>
              </ButtonGroup>
            </Box>
            <Box sx={{ width: '100%' }}>
              <EditableTypography
                variant="h4"
                multiline={false}
                isEditable={isEditable}
                onChange={e => setName(e as string)}
              >
                {name}
              </EditableTypography>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Divider sx={{ borderBottomWidth: 1, bgcolor: 'white' }} />
      <TabContext value={tab}>
        <Box sx={{ width: '100%' }}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{
              '& .MuiTabs-flexContainer': {
                justifyContent: 'space-between',
              },
            }}
          >
            <Tab label="Overview" value="0" sx={{ flexGrow: 1 }} />
            <Tab label="Findings" value="1" sx={{ flexGrow: 1 }} />
          </Tabs>
        </Box>
        <TabPanel
          value="0"
          sx={{ height: '100%', overflow: 'auto', pb: '20px' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" color="text.secondary">
                  Description
                </Typography>
                <EditableTypography
                  isEditable={isEditable}
                  onChange={e => setDescription(e as string)}
                >
                  {description}
                </EditableTypography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="text.secondary">
                Auditors
              </Typography>
              <Box display="flex" gap={1} flexDirection="column">
                {selectedAudit.auditors?.map(
                  auditor =>
                    auditor && (
                      <UserTag
                        key={auditor.id}
                        user={auditor}
                        variant="text"
                        size="medium"
                      />
                    )
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" color="text.secondary">
                  Status
                </Typography>
                <Typography variant="body1">
                  {formatGraphqlEnumToReadableText(status as string)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel
          value="1"
          sx={{
            height: '100%',
            overflow: 'auto',
            pb: '20px',
            '& .MuiTimeline-root': {
              paddingLeft: 0,
            },
            '& .MuiTimelineItem-root': {
              '&:before': {
                display: 'none',
              },
            },
            '& .MuiTimelineContent-root': {
              flex: 3,
              paddingLeft: 2,
            },
          }}
        >
          {findingsData?.governanceAudit?.auditStatuses?.length === 0 ? (
            <Typography color="text.secondary">
              No findings available for this audit.
            </Typography>
          ) : (
            <Timeline>
              {findingsData?.governanceAudit?.auditStatuses?.map(
                status =>
                  status && (
                    <TimelineItem key={status.id}>
                      <TimelineSeparator>
                        <TimelineDot>
                          {getAuditStatusIcon(
                            getHighestSeverityStatus(
                              status.sections || []
                            ) as RequirementAuditStatusEnum
                          )}
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Box
                          sx={{
                            bgcolor: 'background.paper',
                            p: 2,
                            borderRadius: 1,
                            mb: 2,
                          }}
                        >
                          <Typography variant="h6" gutterBottom>
                            {status.materializedRequirement?.title}
                          </Typography>
                          <Divider sx={{ my: 1 }} />

                          {status.sections?.map((section, index) => (
                            <Box
                              key={index}
                              sx={{
                                mt: index > 0 ? 2 : 0,
                                p: 2,
                                bgcolor: 'background.default',
                                borderRadius: 1,
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: 1,
                                  alignItems: 'center',
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  color="text.secondary"
                                >
                                  Status:
                                </Typography>
                                <Typography variant="body2">
                                  {formatGraphqlEnumToReadableText(
                                    section?.status as string
                                  )}
                                </Typography>
                              </Box>

                              {section?.comments && (
                                <Box sx={{ mt: 1 }}>
                                  <Typography
                                    variant="subtitle2"
                                    color="text.secondary"
                                    gutterBottom
                                  >
                                    Comments:
                                  </Typography>
                                  <Typography variant="body2" sx={{ pl: 2 }}>
                                    {section.comments}
                                  </Typography>
                                </Box>
                              )}

                              {section?.evidenceNotes &&
                                section.evidenceNotes.length > 0 && (
                                  <Box sx={{ mt: 1 }}>
                                    <Typography
                                      variant="subtitle2"
                                      color="text.secondary"
                                      gutterBottom
                                    >
                                      Evidence Notes:
                                    </Typography>
                                    <List dense sx={{ pl: 1 }}>
                                      {section?.evidenceNotes?.map(
                                        (note, noteIndex) => (
                                          <ListItem key={noteIndex}>
                                            <ListItemText primary={note} />
                                          </ListItem>
                                        )
                                      )}
                                    </List>
                                  </Box>
                                )}
                            </Box>
                          ))}
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                  )
              )}
            </Timeline>
          )}
        </TabPanel>
      </TabContext>

      <Menu
        anchorEl={moreOptionsAnchorEl}
        open={Boolean(moreOptionsAnchorEl)}
        onClose={() => setMoreOptionsAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            deleteAudit();
            setMoreOptionsAnchorEl(null);
          }}
        >
          <TrashIcon sx={{ mr: 1 }} />
          Delete Audit
        </MenuItem>
      </Menu>
    </>
  );
}
