import {
  MaterializedCategory,
  RequirementCompletionStatusEnum,
  User,
  MaterializedRequirement,
} from '../__generated__/gql/graphql';
import { FilterOption, FilterTypes } from '../types/governace';

export const UNASSIGNED_STATUS = 'unassigned';

interface RequirementFields {
  title?: string | null;
  description?: string | null;
  userStatus?: {
    completionStatus?: RequirementCompletionStatusEnum | null;
  } | null;
  owners?: (User | null)[] | null;
  approvers?: (User | null)[] | null;
}

export class GovernanceFilterService {
  static filterCategories(
    categories: MaterializedCategory[] | undefined,
    filterOptions: FilterOption[]
  ): MaterializedCategory[] {
    if (!categories) return [];
    if (filterOptions.length === 0) return categories;

    return categories
      .map(category => ({
        ...category,
        requirements: category.requirements?.filter(req =>
          filterOptions.every(option => this.matchesFilter(req, option))
        ),
      }))
      .filter(
        category => category.requirements && category.requirements.length > 0
      );
  }

  private static matchesFilter(
    requirement: MaterializedRequirement | null,
    option: FilterOption
  ): boolean {
    switch (option.type) {
      case FilterTypes.TEXT:
        return this.matchesTextSearch(requirement, option.value);
      case FilterTypes.OWNER:
        return this.matchesOwner(requirement, option.value);
      case FilterTypes.APPROVER:
        return this.matchesApprover(requirement, option.value);
      case FilterTypes.STATUS:
        return requirement?.userStatus?.completionStatus === option.value;
      default:
        return true;
    }
  }

  private static matchesTextSearch(
    requirement: RequirementFields | null,
    searchText: string
  ): boolean {
    if (!requirement) return false;
    const lowercaseSearch = searchText.toLowerCase();

    return Boolean(
      requirement.title?.toLowerCase().includes(lowercaseSearch) ||
        requirement.description?.toLowerCase().includes(lowercaseSearch)
    );
  }

  private static matchesOwner(
    requirement: RequirementFields | null,
    value: string
  ): boolean {
    if (!requirement) return false;

    if (value === UNASSIGNED_STATUS) {
      return !requirement.owners || requirement.owners.length === 0;
    }
    return Boolean(requirement.owners?.some(owner => owner?.name === value));
  }

  private static matchesApprover(
    requirement: RequirementFields | null,
    value: string
  ): boolean {
    if (!requirement) return false;

    if (value === UNASSIGNED_STATUS) {
      return !requirement.approvers || requirement.approvers.length === 0;
    }
    return Boolean(
      requirement.approvers?.some(approver => approver?.name === value)
    );
  }
}
