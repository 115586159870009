import React, { FC } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Link,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { colorThemes } from '../theme';
import {
  EnabledRiskAssessmentFrameworksQuery,
  RiskAssessmentNameEnum,
} from '../__generated__/gql/graphql';
import { toast } from 'react-toastify';

interface NewRiskAssessmentModalProps {
  open: boolean;
  onClose: () => void;
}

export const GET_ENABLED_RISK_ASSESSMENT_FRAMEWORKS = gql`
  query EnabledRiskAssessmentFrameworks {
    enabledRiskAssessmentFrameworks {
      id
      templateId
      name
      description
    }
  }
`;

const CREATE_ASSESSMENT_FRAMEWORK_COPY = gql`
  mutation CreateAssessmentFrameworkCopy(
    $projectId: ID!
    $riskAssessmentName: RiskAssessmentNameEnum!
  ) {
    createAssessmentFrameworkCopy(
      projectId: $projectId
      riskAssessmentName: $riskAssessmentName
    ) {
      framework {
        id
        name
        riskAssessmentCatalogueType
      }
    }
  }
`;

const NewRiskAssessmentModal: FC<NewRiskAssessmentModalProps> = ({
  open,
  onClose,
}) => {
  const { projectId } = useParams<{ projectId: string }>();
  const navigate = useNavigate();

  const { data: riskAsessmentFrameworkTemplates } =
    useSuspenseQuery<EnabledRiskAssessmentFrameworksQuery>(
      GET_ENABLED_RISK_ASSESSMENT_FRAMEWORKS
    );

  const [createAssessmentFrameworkCopy] = useMutation(
    CREATE_ASSESSMENT_FRAMEWORK_COPY,
    {
      onCompleted: data => {
        toast.success('Framework created successfully.');
        navigate(
          `/risk_assessment/${projectId}/${data.createAssessmentFrameworkCopy.framework.name}/${data.createAssessmentFrameworkCopy.framework.id}`
        );
        onClose();
      },
      onError: error => {
        toast.error(`An unexpected error occurred: ${error.message}`);
      },
      update(cache, { data }) {
        if (!data) return;

        const newFramework = data.createAssessmentFrameworkCopy.framework;

        cache.modify({
          fields: {
            project(existingProjectRefs = {}) {
              const existingFrameworkRefs =
                existingProjectRefs.riskAssessmentFrameworks || [];
              const newRiskAssessmentFrameworkRef = cache.writeFragment({
                data: newFramework,
                fragment: gql`
                  fragment NewRiskAssessmentFramework on RiskAssessmentFrameworkMaterialized {
                    id
                    name
                    riskAssessmentCatalogueType
                  }
                `,
              });

              return {
                ...existingProjectRefs,
                riskAssessmentFrameworks: [
                  ...existingFrameworkRefs,
                  newRiskAssessmentFrameworkRef,
                ],
              };
            },
          },
        });
      },
    }
  );

  const handleSelect = (name: RiskAssessmentNameEnum) => {
    createAssessmentFrameworkCopy({
      variables: {
        projectId,
        riskAssessmentName: name,
      },
    });
  };

  const assessmentFrameworks =
    riskAsessmentFrameworkTemplates.enabledRiskAssessmentFrameworks?.filter(
      (framework): framework is NonNullable<typeof framework> =>
        framework !== null
    ) ?? [];

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select Risk Assessment Type</DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{ minHeight: '200px', minWidth: '400px' }}
        >
          {assessmentFrameworks.length === 0 ? (
            <Typography variant="body1" color="textSecondary">
              Please enable the frameworks on the{' '}
              <Link href="/organization_settings">Organization Settings</Link>{' '}
              page.
            </Typography>
          ) : (
            assessmentFrameworks.map(({ id, name, description }) => (
              <Card
                key={id}
                sx={{ backgroundColor: colorThemes.DARK_BLUE_700 }}
              >
                <CardActionArea onClick={() => name && handleSelect(name)}>
                  <CardContent>
                    <Typography variant="h6" sx={{ color: 'white' }}>
                      {name || 'Unknown Type'}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {description || 'Unknown Description'}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default NewRiskAssessmentModal;
