import { FC, useState, useCallback } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, TextField, IconButton, Tooltip } from '@mui/material';
import { Clear, Upload as UploadIcon } from '@mui/icons-material';
import { colorThemes } from '../../../theme';

interface MaterialUploadStepProps {
  onFileSelected: (file: File | null) => void;
}

const MaterialUploadStep: FC<MaterialUploadStepProps> = ({
  onFileSelected,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { control } = useFormContext();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setSelectedFile(file);
        onFileSelected(file);
      }
    },
    [onFileSelected]
  );

  const handleRemoveFile = () => {
    setSelectedFile(null);
    onFileSelected(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
    },
    maxSize: 10485760, // 10MB
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="h6" gutterBottom>
        Training Material
      </Typography>

      <Controller
        name="materialDescription"
        control={control}
        defaultValue=""
        rules={{
          required: 'Material description is required',
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Material Description"
            fullWidth
            multiline
            rows={3}
            error={!!error}
            helperText={
              error?.message ||
              'Provide a brief description of the training material'
            }
          />
        )}
      />

      <Box
        {...getRootProps()}
        sx={{
          border: '2px dashed',
          borderColor: colorThemes.DARK_BLUE_100,
          borderRadius: 1,
          p: 3,
          textAlign: 'center',
          cursor: 'pointer',
          bgcolor: _theme =>
            isDragActive
              ? 'action.hover'
              : selectedFile
              ? 'rgba(25, 118, 210, 0.04)'
              : 'background.paper',
          transition: 'all 0.2s ease',
          '&:hover': {
            bgcolor: 'action.hover',
          },
        }}
      >
        <input {...getInputProps()} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <UploadIcon fontSize="large" color="action" />
          {selectedFile ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography>{selectedFile.name}</Typography>
              <Tooltip title="Remove selected file">
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    handleRemoveFile();
                  }}
                  color="error"
                  size="small"
                >
                  <Clear />
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            <Typography>
              {isDragActive
                ? 'Drop the file here...'
                : 'Drag and drop training material, or click to select'}
            </Typography>
          )}
          <Typography variant="caption" color="text.secondary">
            Supported format: PDF (max 10MB)
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MaterialUploadStep;
