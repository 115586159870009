import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  GetTuvRiskAssessmentsQuery,
  RiskAssessmentFrameworkFieldsFragment,
} from '../__generated__/gql/graphql';
import NewRiskAssessmentModal from './NewRiskAssessmentModal';
import { useState } from 'react';
import { colorThemes } from '../theme';
import RiskManagementDashboard from './dashboards/RiskManagementDashboard';
import CardGrid from '../components/CardGrid';

const RISK_ASSESSMENT_FRAMEWORK_FIELDS = gql`
  fragment RiskAssessmentFrameworkFields on RiskAssessmentFrameworkMaterialized {
    id
    name
  }
`;

const GET_TUV_RISK_ASSESSMENTS = gql`
  query getTuvRiskAssessments($projectId: String!) {
    project(id: $projectId) {
      riskAssessmentFrameworks {
        ...RiskAssessmentFrameworkFields
      }
    }
  }
  ${RISK_ASSESSMENT_FRAMEWORK_FIELDS}
`;

export default function RiskManagement() {
  const { projectId } = useParams();
  const { data } = useSuspenseQuery<GetTuvRiskAssessmentsQuery>(
    GET_TUV_RISK_ASSESSMENTS,
    {
      variables: { projectId },
    }
  );

  const riskAssessmentFrameworks =
    data?.project?.riskAssessmentFrameworks?.filter(
      (framework): framework is RiskAssessmentFrameworkFieldsFragment =>
        framework !== null
    ) ?? [];
  const [showRiskAssesmentModal, setShowRiskAssesmentModal] = useState(false);

  return (
    <>
      <Box display="flex" gap={2} flexDirection="column" width="100%">
        <Typography variant="h4" marginBottom={3}>
          Risk Assessments
        </Typography>
        <RiskManagementDashboard />

        <CardGrid
          items={riskAssessmentFrameworks}
          title={
            riskAssessmentFrameworks.length === 0
              ? 'No Frameworks'
              : riskAssessmentFrameworks.length > 1
              ? `${riskAssessmentFrameworks.length} Frameworks`
              : '1 Framework'
          }
          renderCard={assessment => (
            <AssesmentCard
              key={assessment.id}
              assessment={assessment}
              projectId={projectId}
            />
          )}
          onAddNew={() => setShowRiskAssesmentModal(true)}
        />
        <NewRiskAssessmentModal
          open={showRiskAssesmentModal}
          onClose={() => setShowRiskAssesmentModal(false)}
        />
      </Box>
    </>
  );
}

interface AssessmentCardProps {
  assessment: RiskAssessmentFrameworkFieldsFragment;
  projectId?: string;
}

function AssesmentCard({ assessment, projectId }: AssessmentCardProps) {
  const navigate = useNavigate();

  const handleSelect = (name: string, frameworkId: string) => {
    navigate(`/risk_assessment/${projectId}/${name}/${frameworkId}/`);
  };

  return (
    <Card
      sx={{
        width: 400,
        height: 200,
        transition: 'all .1s',
        border: '2px solid #334E68',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: colorThemes.DARK_BLUE_400,
        },
      }}
    >
      <CardActionArea
        sx={{ height: '100%', width: '100%' }}
        onClick={() => handleSelect(assessment.name, assessment.id)}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Typography variant="h6">
            {assessment.name} Risk Assessment
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
