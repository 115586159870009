import React, { useState, useCallback } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import theme from '../theme';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import DownloadIcon from '@mui/icons-material/Download';
import { SimpleTreeView } from '@mui/x-tree-view';

export interface FileNode {
  id: string;
  name: string;
  parentId?: string | null;
  children?: FileNode[];
  url?: string;
}

interface Props {
  files: FileNode[];
  nonSelectableNodes?: string[];
  isDeleteEnabled?: boolean;
  onDeleteFiles: (selectedFiles: string[]) => Promise<void>;
}

export default function FileTreeView({
  files,
  nonSelectableNodes = [],
  isDeleteEnabled = true,
  onDeleteFiles,
}: Props) {
  const fileTree = buildFileTree(files);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleNodeSelect = useCallback(
    (_event: any, nodeIds: string[]) => {
      const selectableNodes = nodeIds.filter(
        id => !nonSelectableNodes.includes(id)
      );
      setSelectedFiles(selectableNodes);
    },
    [nonSelectableNodes]
  );

  const handleDelete = async () => {
    if (isDeleteEnabled && selectedFiles.length > 0) {
      setDeleteLoading(true);
      try {
        await onDeleteFiles(selectedFiles);
        toast.success('Files deleted successfully');
      } catch (e) {
        console.error('Error deleting files:', e);
        toast.error('Failed to delete files');
      }
      setDeleteLoading(false);
    }
  };

  const renderTree = (node: FileNode) => (
    <TreeItem
      key={node.id}
      itemId={node.id}
      label={
        <Box
          display="flex"
          alignItems="center"
          sx={{ '&:hover .downloadIcon': { visibility: 'visible' } }}
        >
          <Typography variant="body1" sx={{ flexGrow: 1 }}>
            {node.name}
          </Typography>
          {node.url && (
            <Tooltip title="Download">
              <IconButton
                className="downloadIcon"
                size="small"
                onClick={e => {
                  e.stopPropagation(); // Prevent TreeItem selection
                  window.open(node?.url);
                }}
                sx={{ visibility: 'hidden' }}
              >
                <DownloadIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      }
    >
      {Array.isArray(node.children) && node.children.length > 0
        ? node.children.map(node => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <>
      {fileTree.length === 0 ? (
        <Typography variant="body1">No files uploaded yet</Typography>
      ) : (
        <Box display="flex" flexDirection="column" gap={theme.spacing(1)}>
          <SimpleTreeView
            aria-label="file system navigator"
            onSelectedItemsChange={handleNodeSelect}
            multiSelect
          >
            {fileTree.map(renderTree)}
          </SimpleTreeView>
          {isDeleteEnabled && (
            <LoadingButton
              loading={deleteLoading}
              sx={{ width: 'fit-content' }}
              disabled={selectedFiles.length === 0}
              onClick={handleDelete}
              variant="outlined"
            >
              Delete Selected Files
            </LoadingButton>
          )}
        </Box>
      )}
    </>
  );
}

function buildFileTree(files: FileNode[]): FileNode[] {
  const tree: FileNode[] = [];
  const map = new Map<string, FileNode>();

  files.forEach(file => {
    map.set(file.id, { ...file, children: [] });
  });

  files.forEach(file => {
    const node = map.get(file.id);
    if (!node) return;
    if (file.parentId) {
      const parentNode = map.get(file.parentId);
      if (parentNode) {
        parentNode.children = [...(parentNode.children || []), node];
      }
    } else {
      tree.push(node);
    }
  });

  return tree.filter(node => node);
}
