import { FC } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { LiteracyTraining } from '../../__generated__/gql/graphql';
import { StyledPaper } from '../../components/StyledPaper';

interface CompletionScreenProps {
  training: LiteracyTraining | null;
  onComplete: () => void;
}

const CompletionScreen: FC<CompletionScreenProps> = ({
  training,
  onComplete,
}) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    gap={4}
    py={4}
    sx={{ width: '100%', height: '100%', position: 'relative' }}
  >
    <StyledPaper elevation={3}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
      >
        <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="h3" fontWeight="bold">
            Congratulations!
          </Typography>
        </Box>

        <Typography variant="h5" sx={{ mb: 4, color: 'text.primary' }}>
          You have successfully completed
        </Typography>

        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{
            mb: 4,
            textShadow: '1px 1px 2px rgba(0,0,0,0.2)',
          }}
        >
          {training?.title}
        </Typography>

        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={onComplete}
          sx={{
            mt: 2,
            px: 6,
            py: 1.5,
            borderRadius: '2rem',
            fontSize: '1.1rem',
            textTransform: 'none',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0 6px 8px rgba(0,0,0,0.2)',
            },
            transition: 'all 0.2s ease-in-out',
          }}
        >
          Complete Training
        </Button>
      </Box>
    </StyledPaper>
  </Box>
);

export default CompletionScreen;
