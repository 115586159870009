import { Box } from '@mui/system';
import { FC, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Typography } from '@mui/material';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  GetLiteracyTrainingsforUserQuery,
  LiteracyTraining,
} from '../__generated__/gql/graphql';
import CardGrid from '../components/CardGrid';
import TrainingDetailCard from './TrainingDetailCard';
import { calculateDueDate } from '../utils/dateUtils';
import TrainingModal from './TrainingModal';

const GET_LITERACY_TRAININGS = gql`
  query GetLiteracyTrainingsforUser {
    allLiteracyTrainings {
      id
      title
      responsiblePerson {
        id
        name
        email
      }
      description
      isRequired
      interval
      trainingMaterial {
        id
        fileName
      }
      updatedAt
      createdAt
      currentUserCompletion {
        id
        completionDate
      }
    }
  }
`;

const TrainingOverviewPage: FC = () => {
  const { data: allTrainingData } =
    useSuspenseQuery<GetLiteracyTrainingsforUserQuery>(GET_LITERACY_TRAININGS);
  const filteredTraining =
    (allTrainingData?.allLiteracyTrainings as LiteracyTraining[]) || [];
  const [selectedTraining, setSelectedTraining] =
    useState<LiteracyTraining | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleTrainingSelect = (training: LiteracyTraining) => {
    setSelectedTraining(training);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedTraining(null);
  };

  return (
    <Box>
      <Typography variant="h4" marginBottom={3}>
        Training Overview
      </Typography>
      {/* <FilterSection /> */}

      <CardGrid
        items={filteredTraining}
        title={
          filteredTraining.length === 0
            ? 'No Trainings available'
            : filteredTraining.length > 1
            ? filteredTraining.length + ' Trainings'
            : '1 Training'
        }
        renderCard={training => (
          <TrainingDetailCard
            key={training.id}
            id={training.id}
            onClick={() => handleTrainingSelect(training)}
            title={training.title}
            isSelected={selectedTraining?.id === training.id}
            required={training?.isRequired ?? false}
            done={!!training.currentUserCompletion}
            nextDue={calculateDueDate(
              training.createdAt,
              training.interval,
              training.currentUserCompletion?.completionDate
            )}
            description={training.description ?? ''}
          />
        )}
        addNewCardProps={{ width: 400, height: 200 }}
      />
      <TrainingModal
        open={isModalOpen}
        onClose={handleModalClose}
        training={selectedTraining}
      />
      <ToastContainer />
    </Box>
  );
};

export default TrainingOverviewPage;
