import {
  gql,
  useLazyQuery,
  useMutation,
  useSuspenseQuery,
} from '@apollo/client';
import FileTreeView, { FileNode } from '../../components/FileTreeView';
import {
  SourceFile,
  UploadedFilesQuery,
} from '../../__generated__/gql/graphql';
import ChipInputList from '../../components/ChipInputList';
import { useState } from 'react';
import SidebarContentField from '../../components/SidebarContentField';
import { Alert, Box, ButtonGroup, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';

const UPDATE_CONFLUENCE_LABELS = gql`
  mutation UpdateExperimentConfluenceLabels(
    $experimentId: String!
    $confluenceLabels: [String]
  ) {
    updateExperiment(
      experimentId: $experimentId
      confluenceLabels: $confluenceLabels
    ) {
      experiment {
        confluenceLabels
      }
    }
  }
`;

const UPLOAD_CONFLUENCE_PAGES = gql`
  mutation AddConfluencePages(
    $projectId: String!
    $experimentId: String!
    $confluencePageIds: [String!]!
  ) {
    addConfluencePages(
      projectId: $projectId
      experimentId: $experimentId
      confluencePageIds: $confluencePageIds
    ) {
      experiment {
        id
      }
    }
  }
`;

const GET_PROJECT_FILES = gql`
  query UploadedFiles($experimentId: String!) {
    experiment(id: $experimentId) {
      id
      confluenceLabels
      confluencePages {
        id
        fileName
      }
      sourceFiles {
        id
        filePath
        fileName
        url
      }
    }
  }
`;

const DELETE_SOURCE_FILES = gql`
  mutation DeleteSourceFiles(
    $fileIds: [String!]!
    $projectId: String!
    $experimentId: String!
  ) {
    deleteSourceFiles(
      fileIds: $fileIds
      projectId: $projectId
      experimentId: $experimentId
    ) {
      deletedSourceFiles {
        id
        fileName
      }
    }
  }
`;

const GET_CONFLUENCE_FILES = gql`
  query ConfluenceFilesByLabel($projectId: String!, $labels: [String]!) {
    confluenceFilesByLabel(projectId: $projectId, labels: $labels) {
      id
      title
    }
  }
`;
const SYNC_REMOTE_GIT_FILES = gql`
  mutation SyncRemoteGitFilesWithExperiment(
    $projectId: String!
    $experimentId: String!
  ) {
    syncRemoteGitFilesWithExperiment(
      projectId: $projectId
      experimentId: $experimentId
    ) {
      experiment {
        sourceFiles {
          id
          filePath
          fileName
          url
        }
      }
    }
  }
`;
interface ConfluencePage {
  id: string;
  title: string;
}

interface Props {
  projectId: string;
  experimentId: string;
  gitSyncEnabled: boolean;
}

export default function FilesTreeView({
  projectId,
  experimentId,
  gitSyncEnabled,
}: Props) {
  const { data } = useSuspenseQuery<UploadedFilesQuery>(GET_PROJECT_FILES, {
    variables: { experimentId },
  });
  const [deleteFiles] = useMutation(DELETE_SOURCE_FILES);

  const isDeleteClickable = true;

  const handleDeleteFiles = async (selectedIds: string[]) => {
    const filesToDelete = new Set<string>();

    selectedIds.forEach(selectedId => {
      files.forEach(file => {
        if ((file?.filePath ?? '').startsWith(selectedId)) {
          filesToDelete.add(file!.id as string);
        }
      });
    });

    if (filesToDelete.size > 0) {
      await deleteFiles({
        variables: {
          fileIds: Array.from(filesToDelete),
          projectId,
          experimentId,
        },
        update(cache) {
          cache.modify({
            id: cache.identify({ __typename: 'Experiment', id: experimentId }),
            fields: {
              sourceFiles(existingFiles = []) {
                return existingFiles.filter((fileRef: any) => {
                  const fileId = fileRef?.__ref?.split(':')[1];
                  return fileId && !filesToDelete.has(fileId);
                });
              },
            },
          });
        },
      });
    }
  };

  const [syncRemoteGitFiles, { loading: isSyncing }] = useMutation(
    SYNC_REMOTE_GIT_FILES,
    {
      variables: { projectId, experimentId },
      update(cache, { data: mutationData }) {
        if (
          mutationData?.syncRemoteGitFilesWithExperiment?.experiment
            ?.sourceFiles
        ) {
          const newSourceFiles =
            mutationData.syncRemoteGitFilesWithExperiment.experiment
              .sourceFiles;

          cache.modify({
            id: cache.identify({ __typename: 'Experiment', id: experimentId }),
            fields: {
              sourceFiles(existingFiles = []) {
                const updatedFiles = newSourceFiles.map(
                  (newFile: SourceFile) => ({
                    ...newFile,
                    __typename: 'SourceFile',
                  })
                );
                return [...existingFiles, ...updatedFiles];
              },
            },
          });
          toast.success('Git files synced successfully');
        }
      },
      onError: error => {
        toast.error(`Failed to sync Git files: ${error.message}`);
      },
    }
  );

  const files =
    (data?.experiment?.sourceFiles ?? []).map(file => ({
      id: file?.id,
      name: file?.fileName,
      filePath: file?.filePath,
      url: file?.url,
    })) || [];
  // const existingConfluencePages = data?.experiment?.confluencePages ?? [];
  const existingConfluencePages =
    (data?.experiment?.confluencePages ?? []).map(file => ({
      id: file?.id ?? '',
      name: file?.fileName ?? '',
    })) || [];

  const { fileTree } = buildFileTree(files);
  const [confluenceLabels, setConfluenceLabels] = useState<string[]>(
    (data?.experiment?.confluenceLabels as string[]) ?? []
  );
  const [updateConfluenceLabels, { loading: isUpdatingConfluenceLabels }] =
    useMutation(UPDATE_CONFLUENCE_LABELS);

  const [associatedFiles, setAssociatedFiles] = useState<ConfluencePage[]>([]);
  const [
    getConfluenceFiles,
    { loading: isFetchingConfluenceFiles, error: confluenceFilesError },
  ] = useLazyQuery(GET_CONFLUENCE_FILES, {
    onCompleted: data => {
      setAssociatedFiles(data?.confluenceFilesByLabel ?? []);
    },
  });

  const [uploadConfluencePages, { loading: isUpdatingConfluencePages }] =
    useMutation(UPLOAD_CONFLUENCE_PAGES);

  return (
    <Box display={'flex'} gap={'20px'} flexDirection={'column'}>
      <SidebarContentField title={'Uploaded Code Files'}>
        <FileTreeView
          files={fileTree}
          isDeleteEnabled={isDeleteClickable}
          onDeleteFiles={handleDeleteFiles}
        />
        <Tooltip
          title={!gitSyncEnabled ? 'No Git repository is connected' : ''}
          arrow
        >
          <span>
            <LoadingButton
              variant="outlined"
              onClick={() => syncRemoteGitFiles()}
              loading={isSyncing}
              sx={{ mt: 2 }}
              disabled={!gitSyncEnabled}
            >
              Sync Git Files
            </LoadingButton>
          </span>
        </Tooltip>
      </SidebarContentField>
      <SidebarContentField title={'Confluence Sources'}>
        <Box display={'flex'} flexDirection={'column'} gap={'20px'}>
          <FileTreeView
            files={existingConfluencePages}
            nonSelectableNodes={[]}
            isDeleteEnabled={false}
            onDeleteFiles={async () => {}}
          />
          <Typography variant={'h6'}>Confluence Labels</Typography>
          <ChipInputList
            items={confluenceLabels}
            setItems={setConfluenceLabels}
          />
          <ButtonGroup variant="outlined">
            <LoadingButton
              sx={{ width: 'fit-content' }}
              variant="outlined"
              disabled={confluenceLabels.length === 0}
              loading={isFetchingConfluenceFiles}
              onClick={() => {
                setAssociatedFiles([]);
                getConfluenceFiles({
                  variables: {
                    experimentId,
                    projectId,
                    labels: confluenceLabels,
                  },
                });
              }}
            >
              Fetch Files
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              loading={isUpdatingConfluenceLabels}
              onClick={async () => {
                try {
                  await updateConfluenceLabels({
                    variables: { experimentId, confluenceLabels },
                  });
                  toast.success('Confluence labels updated successfully');
                } catch {
                  toast.error('Failed to update confluence labels');
                }
              }}
            >
              Save Labels
            </LoadingButton>
          </ButtonGroup>
          {confluenceFilesError && (
            <Alert
              severity="error"
              sx={{
                mb: 2,
                backgroundColor: 'error.main',
                color: 'error.contrastText',
                '& .MuiAlert-icon': {
                  color: 'error.contrastText',
                },
              }}
            >
              Failed to fetch associated Confluence files:{' '}
              {confluenceFilesError.message}
            </Alert>
          )}
          {(associatedFiles ?? []).map(({ title }) => (
            <Typography>{title}</Typography>
          ))}
          {associatedFiles && associatedFiles.length > 0 && (
            <LoadingButton
              loading={isUpdatingConfluencePages}
              onClick={async () => {
                try {
                  await uploadConfluencePages({
                    variables: {
                      projectId,
                      experimentId,
                      confluencePageIds: associatedFiles.map(page => page.id),
                    },
                  });
                  toast.success('Confluence files uploaded successfully');
                } catch {
                  toast.error('Failed to upload Confluence files');
                }
              }}
            >
              Add Pages
            </LoadingButton>
          )}
        </Box>
      </SidebarContentField>
    </Box>
  );
}

function buildFileTree(files: any[]): {
  fileTree: FileNode[];
} {
  const nodesMap = new Map<string, FileNode>();

  files.forEach(file => {
    const segments = file.filePath.split('/');
    segments.reduce((parentId: string, segment: string, index: number) => {
      const isFile = index === segments.length - 1; // Assume last segment is a file if it has an extension
      const id = parentId ? `${parentId}/${segment}` : segment;
      const existingNode = nodesMap.get(id);

      if (!existingNode) {
        nodesMap.set(id, {
          id,
          name: segment,
          parentId: index === 0 ? undefined : parentId,
          url: isFile ? file.url : null,
        });
      }

      return id;
    }, '');
  });

  const fileTree = Array.from(nodesMap.values());

  return { fileTree };
}
