import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { FC, useState } from 'react';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import BasicInfoStep from './steps/BasicInfoStep';
import MaterialUploadStep from './steps/MaterialUploadStep';
import ReviewStep from './steps/ReviewStep';
import { IntervalEnum, User } from '../../__generated__/gql/graphql';

enum CreationStep {
  BASIC_INFO = 0,
  MATERIAL_UPLOAD = 1,
  REVIEW = 2,
}

const CREATE_TRAINING = gql`
  mutation CreateTraining(
    $title: String!
    $description: String!
    $responsiblePersonId: String!
    $interval: IntervalEnum!
    $isRequired: Boolean!
    $materialDescription: String!
    $fileName: String
    $fileContent: String
  ) {
    createTraining(
      title: $title
      description: $description
      responsiblePersonId: $responsiblePersonId
      interval: $interval
      isRequired: $isRequired
      materialDescription: $materialDescription
      fileName: $fileName
      fileContent: $fileContent
    ) {
      training {
        id
        trainingMaterial {
          id
          url
          fileName
        }
      }
    }
  }
`;

interface TrainingCreationModalProps {
  isOpen: boolean;
  onClose: () => void;
  allUser: User[];
}

interface FormValues {
  title: string;
  description: string;
  responsiblePersonId: string;
  interval: IntervalEnum;
  isRequired: boolean;
  materialDescription: string;
}

interface MutationVariables extends FormValues {
  fileName?: string;
  fileContent?: string;
}

const TrainingCreationModal: FC<TrainingCreationModalProps> = ({
  isOpen,
  onClose,
  allUser,
}) => {
  const [activeStep, setActiveStep] = useState<CreationStep>(
    CreationStep.BASIC_INFO
  );
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const formSchema: FormValues = {
    title: '',
    description: '',
    responsiblePersonId: '',
    interval: IntervalEnum.Yearly,
    isRequired: false,
    materialDescription: '',
  };

  const methods = useForm<FormValues>({
    defaultValues: formSchema,
  });

  const { control } = methods;
  const formValues = useWatch({ control }) as FormValues;

  const [createTraining] = useMutation<
    { createTraining: { training: { id: string } } },
    MutationVariables
  >(CREATE_TRAINING, {
    onCompleted: () => {
      onClose();
      toast.success('Training created successfully');
    },
    onError: error => {
      toast.error(`Error creating training: ${error.message}`);
    },
  });

  const handleNext = () => {
    setActiveStep(prevStep => (prevStep + 1) as CreationStep);
  };

  const handleBack = () => {
    setActiveStep(prevStep => (prevStep - 1) as CreationStep);
  };

  const handleCreate = async () => {
    try {
      // Validate required fields
      if (
        !formValues.title ||
        !formValues.description ||
        !formValues.responsiblePersonId ||
        !formValues.materialDescription
      ) {
        toast.error('Please fill in all required fields');
        return;
      }

      let variables: MutationVariables = {
        title: formValues.title,
        description: formValues.description,
        responsiblePersonId: formValues.responsiblePersonId,
        interval: formValues.interval,
        isRequired: formValues.isRequired,
        materialDescription: formValues.materialDescription,
      };

      if (selectedFile) {
        const reader = new FileReader();
        const fileContent = await new Promise<string>(resolve => {
          reader.onload = () => {
            if (reader.result) {
              resolve(reader.result as string);
            }
          };
          reader.readAsDataURL(selectedFile);
        });

        const base64Content = fileContent.split(',')[1];

        variables = {
          ...variables,
          fileName: selectedFile.name,
          fileContent: base64Content,
        };
      }

      await createTraining({
        variables,
      });
    } catch (error) {
      console.error('Error creating training:', error);
      toast.error('Failed to create training');
    }
  };

  const steps = [
    {
      label: 'Basic Information',
      component: <BasicInfoStep allUsers={allUser} />,
    },
    {
      label: 'Training Material',
      component: (
        <MaterialUploadStep
          onFileSelected={file => {
            setSelectedFile(file);
          }}
        />
      ),
    },
    {
      label: 'Review',
      component: (
        <ReviewStep formValues={formValues} selectedFile={selectedFile} />
      ),
    },
  ];

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card sx={{ width: '80%', maxWidth: '800px', p: 3 }}>
        <CardHeader title="Create a new Training" />
        <CardContent>
          <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <FormProvider {...methods}>
            {steps[activeStep].component}

            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}
            >
              <Button
                onClick={handleBack}
                disabled={activeStep === CreationStep.BASIC_INFO}
              >
                Back
              </Button>
              {activeStep === CreationStep.REVIEW ? (
                <Button
                  variant="contained"
                  onClick={handleCreate}
                  disabled={!isStepValid(activeStep, formValues, selectedFile)}
                >
                  Create Training
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={!isStepValid(activeStep, formValues, selectedFile)}
                >
                  Next
                </Button>
              )}
            </Box>
          </FormProvider>
        </CardContent>
      </Card>
    </Modal>
  );
};

const isStepValid = (
  step: CreationStep,
  formValues: Partial<FormValues>,
  selectedFile: File | null
) => {
  switch (step) {
    case CreationStep.BASIC_INFO:
      return !!(
        formValues.title &&
        formValues.description &&
        formValues.responsiblePersonId
      );
    case CreationStep.MATERIAL_UPLOAD:
      return !!selectedFile && !!formValues.materialDescription;
    default:
      return true;
  }
};

export default TrainingCreationModal;
