import {
  Autocomplete,
  TextField,
  InputAdornment,
  Chip,
  ListItemText,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { FilterOption, FilterTypes } from '../../../types/governace';
import { colorThemes } from '../../../theme';

interface FilterSectionProps {
  autocompleteOptions: FilterOption[];
  onFilterChange: (options: FilterOption[]) => void;
}

export const FilterSection: React.FC<FilterSectionProps> = ({
  autocompleteOptions,
  onFilterChange,
}) => {
  const handleFilterChange = (
    _event: React.SyntheticEvent,
    newValue: (string | FilterOption)[],
    _reason: string
  ) => {
    const options: FilterOption[] = newValue.map(item =>
      typeof item === 'string'
        ? { type: FilterTypes.TEXT as const, value: item, label: item }
        : item
    );
    onFilterChange(options);
  };

  return (
    <Autocomplete<FilterOption | string, true, false, true>
      multiple
      options={autocompleteOptions}
      freeSolo
      renderTags={(value: (string | FilterOption)[], getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={typeof option === 'string' ? option : option.label}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={(props, option) => (
        <li {...props}>
          <ListItemText
            primary={typeof option === 'string' ? option : option.label}
          />
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={
            params.InputProps.startAdornment
              ? ''
              : 'Search requirements, users, or status...'
          }
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={handleFilterChange}
      isOptionEqualToValue={(option, value) =>
        typeof option === 'string' || typeof value === 'string'
          ? option === value
          : option.type === value.type && option.value === value.value
      }
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.label
      }
      filterOptions={(options, params) => {
        const filtered = options.filter(option =>
          typeof option === 'string'
            ? option.toLowerCase().includes(params.inputValue.toLowerCase())
            : option.label
                .toLowerCase()
                .includes(params.inputValue.toLowerCase())
        );

        if (
          params.inputValue !== '' &&
          !filtered.some(option =>
            typeof option === 'string'
              ? option === params.inputValue
              : option.value === params.inputValue
          )
        ) {
          filtered.push({
            type: FilterTypes.TEXT as const,
            value: params.inputValue,
            label: `Search title or description for: ${params.inputValue}`,
          });
        }
        return filtered;
      }}
      sx={{
        backgroundColor: colorThemes.DARK_BLUE_600,
        borderRadius: '8px',
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'transparent',
          },
          '&:hover fieldset': {
            borderColor: 'transparent',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'transparent',
          },
        },
      }}
    />
  );
};
