import { red, green } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const colorThemes = {
  ACTION_BLUE: '#3A89FF',
  DARK_BLUE_800: '#001529',
  DARK_BLUE_700: '#082034',
  DARK_BLUE_600: '#102A43',
  DARK_BLUE_400: '#153554',
  DARK_BLUE_200: '#1b446b',
  DARK_BLUE_100: '#486581',
  GREY_600: '#404040',
  GREY_500: '#667085',
  GREY_400: '#606060',
  GREY_300: '#D0D5DD',
  GREY_200: '#829AB1',
  GREY_100: '#E9F2F7',
  YELLOW_200: '#fcd462',
  YELLOW_400: '#FBC62F',
  YELLOW_600: '#a88520',
  GREEN_500: '#228B22',
  RED_500: '#FF0000',
};
export const riskColors = {
  critical: '#c0392b',
  major: '#e74c3c',
  serious: '#f1c40f',
  moderate: '#2ecc71',
  unknown: '#cccccc',
};

const theme = createTheme({
  typography: {
    fontFamily: 'lato',
    h4: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '26px',
      lineHeight: '31px',
      letterSpacing: '0.5px',
    },
  },
  spacing: 8,
  palette: {
    action: {
      disabled: '#FFFFFF',
    },
    background: {
      default: colorThemes.DARK_BLUE_800,
      paper: colorThemes.DARK_BLUE_600,
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#B8C0CC',
      disabled: 'rgba(255, 255, 255, 0.5)',
    },
    primary: {
      main: '#FFD700',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    positive: {
      main: green[500],
    },
    negative: {
      main: red[500],
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'white',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          // Setting the default color to white
          backgroundColor: 'white',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: 'white',
        },
      },
      defaultProps: {
        sx: { color: 'white' },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: colorThemes.DARK_BLUE_600,
        },
        flexContainer: {
          backgroundColor: colorThemes.DARK_BLUE_600,
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          color: colorThemes.GREY_100,
          backgroundColor: colorThemes.DARK_BLUE_600,
          '&.Mui-selected': {
            backgroundColor: colorThemes.DARK_BLUE_400,
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: colorThemes.YELLOW_600,
            border: `1px solid ${colorThemes.YELLOW_600}`,
          },
        },
      },
    },
  },
});

export const listStyles = {
  emptyListBackgroundColor: 'rgb(7, 21, 24, 0.4)',
  listContainerBorderStyle: {
    border: '1px dashed #d0d7d9',
    borderRadius: '4px',
  },
  listItemBackgroundColor: '#0c3154',
};

export default theme;
