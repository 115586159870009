import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { RequirementAuditStatusEnum } from '../__generated__/gql/graphql';
import { colorThemes } from '../theme';

export const getAuditStatusIcon = (status: RequirementAuditStatusEnum) => {
  switch (status) {
    case RequirementAuditStatusEnum.Compliant:
      return <CheckCircleIcon sx={{ color: colorThemes.GREY_600 }} />;
    case RequirementAuditStatusEnum.NonCompliant:
      return <ErrorIcon sx={{ color: colorThemes.GREY_600 }} />;
    case RequirementAuditStatusEnum.NotReviewed:
    default:
      return <HourglassEmptyIcon sx={{ color: colorThemes.GREY_600 }} />;
  }
};
