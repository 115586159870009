import { DateTime, DurationLike } from 'luxon';
import { Interval } from '../__generated__/gql/graphql';

export const calculateDueDate = (
  createdAt: string,
  interval?: Interval | null,
  completionDate?: string | null
): DateTime => {
  const DAYS_UNTIL_DUE = 31; // month initial period

  const creationDate = DateTime.fromISO(createdAt);

  if (completionDate) {
    const lastCompletion = DateTime.fromISO(completionDate);

    if (!interval || interval === 'ONCE') {
      return lastCompletion.plus({ days: DAYS_UNTIL_DUE });
    }

    const intervalMap: Record<Interval, DurationLike> = {
      DAILY: { days: 1 },
      WEEKLY: { weeks: 1 },
      MONTHLY: { months: 1 },
      QUARTERLY: { months: 3 },
      YEARLY: { years: 1 },
      ONCE: { days: DAYS_UNTIL_DUE },
    };

    return lastCompletion.plus(intervalMap[interval]);
  }

  return creationDate.plus({ days: DAYS_UNTIL_DUE });
};
