import { useState } from 'react';
import {
  Box,
  Divider,
  Drawer,
  FormControl,
  Grid,
  MenuItem,
  Select,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { toast } from 'react-toastify';
import { Interval, LiteracyTraining, User } from '../__generated__/gql/graphql';
import ProjectGridItem from '../project_view/ProjectGridItem';
import MenuButton from '../components/MenuButton';
import UserTag from '../components/UserTag';
import EditableTypography from '../project_view/EditableTypography';

const UPDATE_TRAINING = gql`
  mutation UpdateTraining(
    $trainingId: ID!
    $title: String
    $description: String
    $responsiblePerson: String
    $isRequired: Boolean
    $interval: IntervalEnum
  ) {
    updateTraining(
      trainingId: $trainingId
      title: $title
      description: $description
      responsiblePerson: $responsiblePerson
      isRequired: $isRequired
      interval: $interval
    ) {
      training {
        id
        title
        description
        responsiblePerson {
          id
          name
        }
        isRequired
        interval
      }
    }
  }
`;

interface TrainingDrawerProps {
  training: LiteracyTraining;
  isOpen: boolean;
  onClose: () => void;
  allUsers: User[];
}

export default function TrainingDrawer({
  training,
  isOpen,
  onClose,
  allUsers,
}: TrainingDrawerProps) {
  const [isEditable, setIsEditable] = useState(false);
  const [title, setTitle] = useState(training.title);
  const [description, setDescription] = useState(training.description || '');
  const [responsiblePerson, setResponsiblePerson] = useState(
    training.responsiblePerson?.id || ''
  );
  const [isRequired, setIsRequired] = useState(training.isRequired || false);
  const [interval, setInterval] = useState<Interval>(
    training.interval || Interval.Yearly
  );
  const [updateTraining] = useMutation(UPDATE_TRAINING, {
    variables: {
      trainingId: training.id,
      title,
      description,
      responsiblePerson,
      isRequired,
      interval,
    },
    onCompleted: () => {
      toast.success('Training updated successfully');
      setIsEditable(false);
    },
    onError: error => {
      toast.error('Failed to update training');
      console.error('Update training error:', error);
    },
  });

  const resetState = () => {
    setTitle(training.title);
    setDescription(training.description || '');
    setResponsiblePerson(training.responsiblePerson?.id || '');
    setIsRequired(training.isRequired || false);
    setInterval(training.interval || Interval.Yearly);
  };

  if (!isOpen) return null;

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: '80%',
          maxWidth: '1000px',
        },
      }}
    >
      <Box sx={{ width: '100%', height: '100%', bgcolor: 'background.paper' }}>
        <Grid
          sx={{
            width: '100%',
            py: '30px',
            display: 'flex',
            justifyContent: 'start',
          }}
        >
          <Box sx={{ px: '30px', width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'space-between',
                pb: '10px',
              }}
            >
              <Box sx={{ flexGrow: 1, minWidth: 0, mr: 2 }}>
                <EditableTypography
                  variant="h4"
                  multiline={false}
                  isEditable={isEditable}
                  onChange={e => setTitle(e as string)}
                >
                  {title}
                </EditableTypography>
              </Box>
              <Box display="flex" marginLeft="auto" gap={2}>
                <MenuButton
                  variant="contained"
                  startIcon={<EditIcon sx={{ color: 'black' }} />}
                  label={isEditable ? 'Cancel' : 'Edit'}
                  onClick={() => {
                    if (isEditable) {
                      resetState();
                    }
                    setIsEditable(!isEditable);
                  }}
                  items={[]}
                />
                {isEditable && (
                  <MenuButton
                    variant="contained"
                    startIcon={<SaveIcon sx={{ color: 'black' }} />}
                    label="Save"
                    onClick={() => updateTraining()}
                    items={[]}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Divider />
        <Box sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ProjectGridItem
                title="Description"
                content={
                  <EditableTypography
                    isEditable={isEditable}
                    onChange={e => setDescription(e as string)}
                  >
                    {description}
                  </EditableTypography>
                }
              />
            </Grid>
            <Grid item xs={6}>
              <ProjectGridItem
                title="Responsible Person"
                content={
                  <FormControl fullWidth variant="filled">
                    <Select
                      value={responsiblePerson}
                      onChange={e => setResponsiblePerson(e.target.value)}
                      disabled={!isEditable}
                    >
                      {allUsers.map(user => (
                        <MenuItem key={user.id} value={user.id}>
                          <UserTag user={user} variant="text" size="medium" />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
              />
            </Grid>
            <Grid item xs={3}>
              <ProjectGridItem
                title="Required"
                content={
                  <FormControl fullWidth variant="filled">
                    <Select
                      value={isRequired}
                      onChange={e => setIsRequired(e.target.value === 'true')}
                      disabled={!isEditable}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </FormControl>
                }
              />
            </Grid>
            <Grid item xs={3}>
              <ProjectGridItem
                title="Interval"
                content={
                  <FormControl fullWidth variant="filled">
                    <Select
                      value={interval}
                      onChange={e => setInterval(e.target.value as Interval)}
                      disabled={!isEditable}
                    >
                      {Object.values(Interval).map(intervalValue => (
                        <MenuItem key={intervalValue} value={intervalValue}>
                          {intervalValue.charAt(0).toUpperCase() +
                            intervalValue.slice(1).toLowerCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
}
