import { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  FormHelperText,
} from '@mui/material';
import { Interval, User } from '../../../__generated__/gql/graphql';
import UserTag from '../../../components/UserTag';

interface BasicInfoFormData {
  title: string;
  description: string;
  responsiblePersonId: string;
  interval: Interval;
  isRequired: boolean;
}

interface BasicInfoStepProps {
  allUsers: User[];
}
const BasicInfoStep: FC<BasicInfoStepProps> = ({ allUsers }) => {
  const { control } = useFormContext<BasicInfoFormData>();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Controller
        name="title"
        control={control}
        rules={{
          required: 'Title is required',
          minLength: {
            value: 3,
            message: 'Title must be at least 3 characters long',
          },
          maxLength: {
            value: 100,
            message: 'Title must be less than 100 characters',
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Title"
            fullWidth
            required
            error={!!error}
            helperText={error?.message}
            placeholder="Enter training title"
          />
        )}
      />

      <Controller
        name="description"
        control={control}
        rules={{
          required: 'Description is required',
          minLength: {
            value: 10,
            message: 'Description must be at least 10 characters long',
          },
          maxLength: {
            value: 1000,
            message: 'Description must be less than 1000 characters',
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Description"
            fullWidth
            multiline
            rows={4}
            required
            error={!!error}
            helperText={error?.message}
            placeholder="Enter training description"
          />
        )}
      />

      <Controller
        name="responsiblePersonId"
        control={control}
        rules={{ required: 'Responsible person is required' }}
        render={({ field, fieldState: { error } }) => (
          <FormControl fullWidth error={!!error} required>
            <InputLabel>Responsible Person</InputLabel>
            <Select
              {...field}
              label="Responsible Person"
              placeholder="Select responsible person"
            >
              {allUsers.map((user, userIndex) => (
                <MenuItem key={userIndex} value={user.id}>
                  <UserTag
                    key={user.id}
                    user={user}
                    variant={'text'}
                    size={'medium'}
                  />
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>
        )}
      />

      <Controller
        name="interval"
        control={control}
        defaultValue={Interval.Yearly}
        render={({ field }) => (
          <FormControl fullWidth>
            <InputLabel>Interval</InputLabel>
            <Select {...field} label="Interval">
              {Object.values(Interval).map(intervalValue => (
                <MenuItem key={intervalValue} value={intervalValue}>
                  {intervalValue.charAt(0).toUpperCase() +
                    intervalValue.slice(1).toLowerCase()}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              How often should this training be repeated?
            </FormHelperText>
          </FormControl>
        )}
      />

      <Controller
        name="isRequired"
        control={control}
        defaultValue={false}
        render={({ field: { value, onChange, ...field } }) => (
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  {...field}
                  checked={value}
                  onChange={e => onChange(e.target.checked)}
                  color="primary"
                />
              }
              label="Required Training"
            />
            <FormHelperText>
              Toggle if this training is mandatory for all users
            </FormHelperText>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default BasicInfoStep;
