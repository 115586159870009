import { MaterializedRequirement } from '../../__generated__/gql/graphql';
import { RequirementDrawer } from './requirement_drawer/RequirementDrawer';
import { RequirementCard } from './RequirementCard';

const RequirementWrapper: React.FC<{
  requirement: MaterializedRequirement;
  totalNumberOfSpecs: number;
  openRequirementId: string | null;
  setOpenRequirementId: (_requirementId: string | null) => void;
  auditId?: string;
}> = ({
  requirement,
  totalNumberOfSpecs,
  openRequirementId,
  setOpenRequirementId,
  auditId,
}) => {
  const isDrawerOpen = requirement.requirementId === openRequirementId;

  const handleRequirementClick = () => {
    if (isDrawerOpen) {
      setOpenRequirementId(null);
    } else {
      setOpenRequirementId(requirement.requirementId || null);
    }
  };

  const totalNumberOfEvidences = requirement.userStatus?.evidence?.length ?? 0;

  return (
    <>
      <RequirementCard
        requirement={requirement}
        specificationNumber={totalNumberOfSpecs}
        evidenceNumber={totalNumberOfEvidences}
        onClick={handleRequirementClick}
      />
      {isDrawerOpen && (
        <RequirementDrawer
          requirement={requirement}
          open={isDrawerOpen}
          onClose={() => setOpenRequirementId(null)}
          auditId={auditId}
        />
      )}
    </>
  );
};

export default RequirementWrapper;
