import {
  MaterializedCategory,
  RequirementCompletionStatusEnum,
} from '../__generated__/gql/graphql';
export interface ProgressStats {
  completedCount: number;
  totalCount: number;
}

export class GovernanceAnalyticsService {
  static countCategoryRequirements(
    category: MaterializedCategory
  ): ProgressStats {
    let completedCount = 0;
    let totalCount = 0;

    category.requirements?.forEach(requirement => {
      totalCount++;
      if (
        requirement?.userStatus?.completionStatus ===
        RequirementCompletionStatusEnum.Approved
      ) {
        completedCount++;
      }
    });

    return { completedCount, totalCount };
  }

  static countCategoryProgress(category: MaterializedCategory): ProgressStats {
    let completedCount = 0;
    let totalCount = 0;

    (category?.requirements ?? []).forEach(requirement => {
      totalCount++;
      if (
        requirement?.userStatus?.completionStatus ===
        RequirementCompletionStatusEnum.Approved
      ) {
        completedCount++;
      }
    });

    return { completedCount, totalCount };
  }

  static countTotalProgress(categories: MaterializedCategory[]): ProgressStats {
    return (categories ?? []).reduce(
      (acc, category) => {
        const stats = this.countCategoryProgress(category);
        return {
          completedCount: acc.completedCount + stats.completedCount,
          totalCount: acc.totalCount + stats.totalCount,
        };
      },
      { completedCount: 0, totalCount: 0 }
    );
  }
}
