import { Box } from '@mui/system';
import { FC, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Typography } from '@mui/material';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  GetallUsersforTrainingQuery,
  GetLiteracyTrainingsQuery,
  Interval,
  LiteracyTraining,
  User,
} from '../__generated__/gql/graphql';
import TrainingCreationModal from './training_creation_modal/TrainingCreationModal';
import TrainingDrawer from './TrainingsDrawer';
import CardGrid from '../components/CardGrid';
import TrainingAdminDetailCard from './TrainingAdminDetailCard';

const GET_LITERACY_TRAININGS = gql`
  query GetLiteracyTrainings {
    allLiteracyTrainings {
      id
      title
      responsiblePerson {
        id
        name
        email
      }
      description
      isRequired
      interval
      trainingMaterial {
        id
        fileName
      }
      updatedAt
      createdAt
    }
  }
`;

const GET_USERS = gql`
  query getallUsersforTraining {
    allUsers {
      id
      email
      name
      initials
    }
  }
`;

const TrainingAdminDashboardPage: FC = () => {
  const { data: allTrainingData } = useSuspenseQuery<GetLiteracyTrainingsQuery>(
    GET_LITERACY_TRAININGS
  );
  const filteredTraining =
    (allTrainingData?.allLiteracyTrainings as LiteracyTraining[]) || [];
  const { data: allUserData } =
    useSuspenseQuery<GetallUsersforTrainingQuery>(GET_USERS);
  const [selectedTraining, setSelectedTraining] =
    useState<LiteracyTraining | null>(null);
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const handleTrainingSelect = (training: LiteracyTraining) => {
    setSelectedTraining(training);
    setShowDrawer(true);
  };
  return (
    <Box>
      <Typography variant="h4" marginBottom={3}>
        Admin Training Dashboard
      </Typography>
      {/* <FilterSection /> */}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ minWidth: '1230px', marginBottom: 2 }}>
          <Typography variant="h4">
            {filteredTraining.length === 0
              ? 'No Trainings created yet'
              : filteredTraining.length > 1
              ? filteredTraining.length + ' Trainings created'
              : '1 Training created'}
          </Typography>
        </Box>
      </Box>

      <CardGrid
        items={filteredTraining}
        renderCard={training => (
          <TrainingAdminDetailCard
            key={training.id}
            id={training.id}
            onClick={() => handleTrainingSelect(training)}
            title={training.title}
            isSelected={selectedTraining?.id === training.id}
            user={training?.responsiblePerson ?? {}}
            interval={training?.interval ?? Interval.Yearly}
            updatedAt={training?.updatedAt ?? 'not update yet'}
            required={training?.isRequired ?? false}
          />
        )}
        onAddNew={() => setShowCreationModal(true)}
        addNewCardProps={{ width: 400, height: 200 }}
      />

      {showCreationModal && (
        <TrainingCreationModal
          isOpen={showCreationModal}
          onClose={() => setShowCreationModal(false)}
          allUser={(allUserData.allUsers as User[]) || []}
        />
      )}

      {selectedTraining && (
        <TrainingDrawer
          training={selectedTraining}
          isOpen={showDrawer}
          onClose={() => {
            setShowDrawer(false);
            setSelectedTraining(null);
          }}
          allUsers={(allUserData?.allUsers as User[]) || []}
        />
      )}

      <ToastContainer />
    </Box>
  );
};

export default TrainingAdminDashboardPage;
