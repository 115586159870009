import { Box, BoxProps, Typography } from '@mui/material';
import { colorThemes } from '../../../theme';
import { ProgressBar } from '../../../components/ProgressBar';

const SummaryCard: React.FC<
  {
    progressLabel: string;
    description: string;
    totalNumber: number;
    fulfilledNumber: number;
  } & BoxProps
> = ({
  progressLabel,
  description,
  totalNumber,
  fulfilledNumber,
  ...props
}) => {
  return (
    <Box
      padding={4}
      paddingRight={5}
      bgcolor={colorThemes.DARK_BLUE_600}
      borderRadius="8px"
      display="flex"
      flexDirection="column"
      gap="4px"
      {...props}
    >
      <Typography variant="h4" component="h2">
        {fulfilledNumber}/{totalNumber}
      </Typography>
      <Typography component="p">{progressLabel}</Typography>
      <ProgressBar
        variant="determinate"
        value={(fulfilledNumber / totalNumber) * 100}
      />
      <Typography component="p">{description}</Typography>
    </Box>
  );
};

export default SummaryCard;
