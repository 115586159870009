import { FC } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { LiteracyTraining } from '../../__generated__/gql/graphql';
import { StyledPaper } from '../../components/StyledPaper';

interface WelcomeScreenProps {
  training: LiteracyTraining | null;
  onStart?: () => void;
}

const WelcomeScreen: FC<WelcomeScreenProps> = ({ training, onStart }) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    gap={4}
    py={4}
    sx={{ width: '100%', height: '100%' }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        mb: 4,
      }}
    >
      <Typography
        variant="h3"
        fontWeight="bold"
        textAlign="center"
        color="primary.main"
        sx={{
          textShadow: '2px 2px 4px rgba(0,0,0,0.2)',
          mb: 2,
        }}
      >
        Welcome to {training?.title}
      </Typography>
    </Box>

    <StyledPaper elevation={3}>
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h6"
          textAlign="center"
          color="text.primary"
          sx={{
            whiteSpace: 'pre-line',
            lineHeight: 1.8,
            mb: 3,
          }}
        >
          {training?.description}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'center',
        }}
      >
        <Typography variant="body1" color="text.secondary" textAlign="center">
          This training will help you understand important concepts and improve
          your skills.
        </Typography>

        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={onStart}
          sx={{
            mt: 2,
            px: 6,
            py: 1.5,
            borderRadius: '2rem',
            fontSize: '1.1rem',
            textTransform: 'none',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0 6px 8px rgba(0,0,0,0.2)',
            },
            transition: 'all 0.2s ease-in-out',
          }}
        >
          Start Training
        </Button>
      </Box>
    </StyledPaper>

    <Box
      sx={{
        mt: 'auto',
        p: 2,
        borderRadius: '1rem',
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
      }}
    >
      <Typography variant="body2" color="text.secondary" textAlign="center">
        Please read through all materials carefully to complete the training
        successfully.
      </Typography>
    </Box>
  </Box>
);

export default WelcomeScreen;
