import { Box, Typography } from '@mui/material';
import { ProgressBar } from '../../../components/ProgressBar';
import RequirementWrapper from '../RequirementWrapper';
import { MaterializedCategory } from '../../../__generated__/gql/graphql';
import { CustomList } from '../../../components/CustomList';
import { generateTypesafeUrl } from '../../../utils/urlHelpers';
import { GovernanceAnalyticsService } from '../../../services/governanceAnalyticsService';

interface CategoryProps {
  category: MaterializedCategory;
  openRequirementId: string | null;
  setOpenRequirementId: (_requirementId: string | null) => void;
  auditId?: string;
}

const Category: React.FC<CategoryProps> = ({
  category,
  openRequirementId,
  setOpenRequirementId,
  auditId,
}) => {
  const progress = GovernanceAnalyticsService.countCategoryProgress(category);
  const progressPercentage =
    (progress.completedCount / progress.totalCount) * 100;

  return (
    <Box component="li" sx={{ marginBottom: 4, mx: 4, mt: 1 }}>
      <Box
        display="grid"
        gridTemplateColumns="1fr 200px"
        alignItems="center"
        paddingRight="8px"
        width="100%"
        marginBottom={2}
      >
        <Typography
          id={generateTypesafeUrl(category?.categoryName)}
          component="h2"
          fontWeight={800}
          fontSize="26px"
          display="inline"
          pt={2}
        >
          {category?.categoryName}
        </Typography>
        <ProgressBar value={progressPercentage} variant="determinate" />
      </Box>
      <CustomList style={{ display: 'grid', gap: '8px', paddingLeft: 0 }}>
        {category?.requirements?.map(requirement => (
          <RequirementWrapper
            key={requirement?.id}
            requirement={requirement!}
            totalNumberOfSpecs={requirement?.specifications?.length ?? 0}
            openRequirementId={openRequirementId}
            setOpenRequirementId={setOpenRequirementId}
            auditId={auditId}
          />
        ))}
      </CustomList>
    </Box>
  );
};

export default Category;
