import { useLocation, useNavigate } from 'react-router-dom';

export const useRequirementNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const openRequirementId = queryParams.get('requirementId');

  const setOpenRequirementId = (requirementId: string | null) => {
    const newQueryParams = new URLSearchParams(location.search);
    if (requirementId) {
      newQueryParams.set('requirementId', requirementId);
    } else {
      newQueryParams.delete('requirementId');
    }
    navigate(
      {
        pathname: location.pathname,
        search: newQueryParams.toString(),
      },
      { replace: true }
    );
  };

  return { openRequirementId, setOpenRequirementId };
};
