import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FC } from 'react';
import { colorThemes } from '../theme';

const NavigationButton: FC<{
  direction: 'back' | 'next';
  onClick: () => void;
  show?: boolean;
}> = ({ direction, onClick, show = true }) => {
  if (!show) return null;

  return (
    <IconButton
      onClick={onClick}
      sx={{
        backgroundColor: colorThemes.ACTION_BLUE,
        '&:hover': { bgcolor: colorThemes.DARK_BLUE_700 },
        width: '40px',
        height: '40px',
      }}
    >
      {direction === 'back' ? <NavigateBefore /> : <NavigateNext />}
    </IconButton>
  );
};

export default NavigationButton;
